import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from "react-router-dom";
import { useStore } from '../stores/store';
import { observer } from 'mobx-react-lite';
import { history } from '../..';
import { blue } from '@mui/material/colors';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import BadgeIcon from '@mui/icons-material/Badge';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import logo from './whitelogo.png';
import CampaignIcon from '@mui/icons-material/Campaign';
import GroupsIcon from '@mui/icons-material/Groups';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ArticleIcon from '@mui/icons-material/Article';

export default observer(function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const { userStore } = useStore();
  const [openShiftsAnchor, setOpenShiftsAnchor] = React.useState<null | HTMLElement>(null);
  const [schedulingAnchor, setSchedulingAnchor] = React.useState<null | HTMLElement>(null);

  const handleOpenShiftsOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenShiftsAnchor(event.currentTarget);
  };

  const handleOpenShiftsClose = () => {
    setOpenShiftsAnchor(null);
  };

  const handleSchedulingOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSchedulingAnchor(event.currentTarget);
  };

  const handleSchedulingClose = () => {
    setSchedulingAnchor(null);
  };

  // const showReporting = () => {
  //   let show = window.localStorage.getItem('isregional');
  //   if (show == "false" || show == null) {
  //     return false;
  //   }
  //   else {
  //     if (showSchedule()) {
  //       return true;
  //     }
  //     else {
  //       return false;
  //     }
  //   }
  // }

  const showSchedule = () => {
    let enablescheduling = window.localStorage.getItem('enablescheduling');

    if (enablescheduling == null) {
      return false;
    }
    else {
      if (enablescheduling == "true") {
        return true;
      }
      else {
        return false;
      }
    }
  }

  const [showWhatsNew, setShowWhatsNew] = React.useState(false);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseLogout = () => {
    setAnchorElUser(null);
    logout();
  };

  let navigate = useNavigate();
  const logout = () => {
    userStore.logout();
    history.push("/login");
    history.go(0);
  }

  const handleShowWhatsNew = () => {
    setAnchorElUser(null);
    setShowWhatsNew(true);
  }
  const handShowWhatsNewClose = () => {
    setShowWhatsNew(false);
  }

  const handleGoToPreferences = () => {
    history.push("/preferences");
    history.go(0);
  }
  const handleGoToRoleSetup = () => {
    history.push("/rolesetup");
    history.go(0);
  }
  const handleGoToShiftSetup = () => {
    history.push("/shiftsetup");
    history.go(0);
  }
  const handleGoToUnitSetup = () => {
    history.push("/unitsetup");
    history.go(0);
  }
  const handleGoToClaimAlerts = () => {
    history.push("/claimalerts");
    history.go(0);
  }
  const handleGoToUsage = () => {
    history.push("/usage");
    history.go(0);
  }
  const handleGoToTemplates = () => {
    history.push("/templates");
    history.go(0);
  }
  const handleGoToRotationSetup = () => {
    history.push("/rotationsetup");
    history.go(0);
  }
  const handleGoToPPDSetup = () => {
    history.push("/ppdsetup");
    history.go(0);
  }
  const handleGoToUsers = () => {
    setAnchorElNav(null);
    history.push("/users");
    history.go(0);
  }
  const handleGoToResponses = () => {
    setAnchorElNav(null);
    history.push("/responses");
    history.go(0);
  }
  const handleGoToShifts = () => {
    setAnchorElNav(null);
    history.push("/shifts");
    history.go(0);
  }
  const handleGoToSchedule = () => {
    setAnchorElNav(null);
    history.push("/schedule");
    history.go(0);
  }
  const handleGoToRotations = () => {
    setAnchorElNav(null);
    history.push("/rotations");
    history.go(0);
  }
  const handleGoToReporting = () => {
    setAnchorElNav(null);
    history.push("/reporting");
    history.go(0);
  }

  const handleGoToContact = () => {
    setAnchorElNav(null);
    history.push("/contact");
    history.go(0);
  }
  const handleGoToRegister = () => {
    setAnchorElNav(null);
    history.push("/entercompanycode");
    history.go(0);
  }
  const handleGoToViewShifts = () => {
    setAnchorElNav(null);
    history.push("/viewusershifts");
    history.go(0);
  }
  const handleGoToLogin = () => {
    setAnchorElNav(null);
    history.push("/login");
    history.go(0);
  }
  const handleGoToUserManual = () => {
    setAnchorElNav(null);
    history.push("/viewusermanual");
    history.go(0);
  }

  return (
    <AppBar position="static" sx={{ background: 'linear-gradient(45deg, #1976d2, #68e5b2)' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <CalendarMonthIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="https://fillopenshifts.com"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            FILL OPEN SHIFTS
          </Typography> */}
          {userStore.isLoggedIn ? (
            <>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {/* {pages.map((page) => ( */}
                  <MenuItem key={0} onClick={handleGoToUsers}>
                    <Typography textAlign="center">Users</Typography>
                  </MenuItem>
                  {/* <MenuItem key={1} component={Link} to="/templates">
                  Templates
                </MenuItem> */}
                  <MenuItem key={1} onClick={handleGoToResponses}>
                    Responses
                  </MenuItem>
                  <MenuItem key={2} onClick={handleGoToShifts}>
                    Shifts
                  </MenuItem>
                  {showSchedule() ?
                    <MenuItem key={3} onClick={handleGoToSchedule}>
                      Schedule
                    </MenuItem>
                    : <></>}
                  {showSchedule() ?
                    <MenuItem key={4} onClick={handleGoToRotations}>
                      Rotations
                    </MenuItem>
                    : <></>}
                  {showSchedule() ?
                    <MenuItem key={5} onClick={handleGoToReporting}>
                      Reporting
                    </MenuItem>
                    : <></>}
                </Menu>
                <a href="/">
                  <img src={logo} height={55} style={{ marginTop: 2 }} />
                </a>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <a href="/">
                  <img src={logo} height={55} style={{ marginTop: 2 }} />
                </a>
                {/* {pages.map((page) => ( */}
                <Button key={0}
                  component={Link} to="/users" onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none', marginLeft: 4 }}
                >
                  Users
                </Button>
                {/* <Button key={1}
                  component={Link} to="/templates" onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block', textTransform:'none' }}
                >
                  Templates
                </Button> */}
                <Button key={1}
                  component={Link} to="/responses" onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none' }}
                >
                  Responses
                </Button>
                <Button key={2}
                  component={Link} to="/shifts" onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none' }}
                >
                  Shifts
                </Button>
                {showSchedule() ?
                  <Button key={3}
                    component={Link} to="/schedule" onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none' }}
                  >
                    Schedule
                  </Button>
                  : <></>}
                {showSchedule() ?
                  <Button key={4}
                    component={Link} to="/rotations" onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none' }}
                  >
                    Rotations
                  </Button>
                  : <></>}
                {/* <Button key={5}
                  component={Link} to="/schedule2" onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none' }}
                >
                  Schedule2
                </Button> */}
                {showSchedule() ?
                  <Button key={6}
                    component={Link} to="/reporting" onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none' }}
                  >
                    Reporting
                  </Button>
                  : <></>}
                {/* ))} */}
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar sx={{ bgcolor: '#1976d2' }}>{userStore.getFirstLetterOfName}</Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {userStore.isLoggedIn ? (
                    <Typography color={blue[400]} textAlign="center" sx={{ fontWeight: 'bold', m: 1 }}>Company: {window.localStorage.getItem('cono')}</Typography>
                  )
                    : <></>}
                  <MenuItem key={1} onClick={handleGoToRoleSetup}>
                    <ListItemIcon>
                      <BadgeIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography textAlign="center">Role Setup</Typography>
                  </MenuItem>
                  <MenuItem key={2} onClick={handleGoToShiftSetup}>
                    <ListItemIcon>
                      <PunchClockIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography textAlign="center">Shift Setup</Typography>
                  </MenuItem>
                  <MenuItem key={8} onClick={handleGoToUnitSetup}>
                    <ListItemIcon>
                      <GroupsIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography textAlign="center">Unit Setup</Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem key={3} onClick={handleGoToClaimAlerts}>
                    <ListItemIcon>
                      <AnnouncementIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography textAlign="center">Claimed Alerts</Typography>
                  </MenuItem>
                  {/* <MenuItem key={4} onClick={handleGoToTemplates}>
                    <ListItemIcon>
                      <DateRangeIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography textAlign="center">Alert Template Setup</Typography>
                  </MenuItem> */}
                  {showSchedule() ?
                    <>
                      <Divider />
                      <MenuItem key={9} onClick={handleGoToRotationSetup}>
                        <ListItemIcon>
                          <EventRepeatIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography textAlign="center">Rotation Setup</Typography>
                      </MenuItem>
                      <MenuItem key={11} onClick={handleGoToPPDSetup}>
                        <ListItemIcon>
                          <SupervisedUserCircleIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography textAlign="center">PPD Setup</Typography>
                      </MenuItem>
                    </>
                    :
                    <></>}
                  <Divider />
                  <MenuItem key={0} onClick={handleGoToPreferences}>
                    <ListItemIcon>
                      <SettingsIcon fontSize="small" color='primary' />
                    </ListItemIcon>
                    <Typography textAlign="center">Preferences</Typography>
                  </MenuItem>
                  <MenuItem key={6} onClick={handleShowWhatsNew}>
                    <ListItemIcon>
                      <CampaignIcon fontSize="small" color='success' />
                    </ListItemIcon>
                    <Typography textAlign="center">What's New</Typography>
                  </MenuItem>
                  <MenuItem key={7} onClick={handleGoToUserManual}>
                    <ListItemIcon>
                      <ArticleIcon fontSize="small" color='warning' />
                    </ListItemIcon>
                    <Typography textAlign="center">User Manual</Typography>
                  </MenuItem>
                  <MenuItem key={5} onClick={handleCloseLogout}>
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" color='error' />
                    </ListItemIcon>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                  <Typography fontSize={10} textAlign="right" sx={{ m: 1 }}>v 1.0.8</Typography>
                </Menu>
              </Box></>
          ) : (
            <>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <a href="/">
                  <img src={logo} height={55} style={{ marginTop: 2 }} />
                </a>
                <Button
                  component={Link} to="/contact"
                  sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none' }}>
                  Contact
                </Button>
                <Button
                  component={Link} to="/entercompanycode"
                  sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none' }}>
                  Register
                </Button>
                <Button
                  component={Link} to="/viewusershifts"
                  sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none' }}>
                  View Shifts
                </Button>
                <Button
                  component={Link} to="/login"
                  sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none' }}>
                  Log In
                </Button>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  <MenuItem key={0} onClick={handleGoToContact}>
                    <Typography textAlign="center">Contact</Typography>
                  </MenuItem>
                  <MenuItem key={1} onClick={handleGoToRegister}>
                    <Typography textAlign="center">Register</Typography>
                  </MenuItem>
                  <MenuItem key={2} onClick={handleGoToViewShifts}>
                    <Typography textAlign="center">View Shifts</Typography>
                  </MenuItem>
                  <MenuItem key={3} onClick={handleGoToLogin}>
                    <Typography textAlign="center">Log In</Typography>
                  </MenuItem>
                </Menu>
                <a href="/">
                  <img src={logo} height={55} style={{ marginTop: 2 }} />
                </a>
              </Box>
            </>
          )}
        </Toolbar>
        <Dialog
          open={showWhatsNew}
          onClose={handShowWhatsNewClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="h5" align="center">What's New</Typography>
          </DialogTitle>
          <DialogContent>
            <List>
            <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h6" component="span">
                      Version 1.0.6
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography component="span" variant="body2">
                        • New UI for users to view their schedule and claim shifts
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h6" component="span">
                      Version 1.0.4
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography component="span" variant="body2">
                        • Added Shift Key import data to Schedule
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2">
                        • Added Fill Open Shifts data to Schedule
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h6" component="span">
                      Version 1.0.3
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography component="span" variant="body2">
                        • Added button to schedule to copy scheduled shifts to a future date
                      </Typography>
                      <br />
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h6" component="span">
                      Version 1.0.1
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography component="span" variant="body2">
                        • Schedule now displays agency users at the top of the list
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2">
                        • Added a button to the Schedule to add a scheduled shift for multiple users and days of week all at once
                      </Typography>
                      <br />
                    </React.Fragment>
                  }
                />
              </ListItem>              
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handShowWhatsNewClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </AppBar >
  );
})