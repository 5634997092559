import React, { useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, LinearProgress, List, ListItem, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useStore } from '../../stores/store';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { FormikConsumer, useFormik } from 'formik';
import { format } from 'date-fns';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { NewShiftInRotation } from '../../models/Schedule/NewShiftInRotation';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Yup from "yup";
import { User } from '../../models/User';
import { BatchAddUsersToShiftsDTO } from '../../models/Schedule/BatchAddUsersToShiftsDTO';

interface SelectRoleDialogProps {
    sundaydate: Date;
    open: boolean;
    onClose: (value: boolean) => void;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 350,
        },
    },
};

interface FormValues {
    selectedunitobject: string,
    startTime: Date,
    endTime: Date,
    assignedUsers: string[]
}


export default observer(function BatchAddUserShiftsToScheduleDialog(props: SelectRoleDialogProps) {
    const { shiftStore, scheduleStore, userStore } = useStore();
    const { getCompanyRoles, loadUsers, getAllUsers } = userStore;
    const { loadShiftSetupsForRole, getShiftSetupsForRole } = shiftStore;
    const { loadUsersForRoleAndDate, putNewShiftInRotation, loadingDetails,
        loadUnitSetups, loading, getUnitSetups, removeShiftFromSchedule, putEditUserShiftInSchedule } = scheduleStore;
    const { onClose, open } = props;
    const [selectedroleobject, setSelectedRoleObject] = React.useState('');
    const [selectedshiftobject, setSelectedShiftObject] = React.useState('');
    const [selectedunitobject, setSelectedUnitObject] = React.useState('');

    const [originalStartTime, setOriginalStartTime] = React.useState(new Date()); //used to see if we are doing a partial shift
    const [originalEndTime, setOriginalEndTime] = React.useState(new Date());
    const [error, setError] = React.useState('');
    const [loadingEdit, setLoadingEdit] = React.useState(false);
    const [areYouSureDeleteShiftOpen, setAreYouSureDeleteShiftOpen] = React.useState(false);
    const [shiftSelectedError, setShiftSelectedError] = React.useState(false);
    const [roleSelectedError, setRoleSelectedError] = React.useState(false);
    const [daysSelectedError, setDaysSelectedError] = React.useState(false);
    const [usersSelectedError, setUsersSelectedError] = React.useState(false);
    const [selectedDays, setSelectedDays] = React.useState<string[]>([]);
    const daysofweek: string[] = [
        "SUNDAY",
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY"
    ];
    const sortDays = (days: string[]): string[] => {
        return [...days].sort((a, b) => daysofweek.indexOf(a) - daysofweek.indexOf(b));
    };
    const DAY_ABBREVIATIONS: { [key: string]: string } = {
        'SUNDAY': 'Sun',
        'MONDAY': 'Mon',
        'TUESDAY': 'Tue',
        'WEDNESDAY': 'Wed',
        'THURSDAY': 'Thu',
        'FRIDAY': 'Fri',
        'SATURDAY': 'Sat'
    };
    const abbreviateDay = (day: string): string => DAY_ABBREVIATIONS[day] || day;
    const handleChangeDOW = (event: SelectChangeEvent<typeof selectedDays>) => {
        const {
            target: { value },
        } = event;
        setSelectedDays(
            typeof value === 'string' ? value.split(',') : value
        );
    };
    useEffect(() => {
        if (props.open) {
            setLoadingEdit(false);
            loadUsers();
            //loadShiftSetupsForRole(props.roleid);
            setLoadingEdit(false);
            setShiftSelectedError(false);
            setRoleSelectedError(false);
            setUsersSelectedError(false);
            setDaysSelectedError(false);
        }

    }, []);

    function excludeRoles(user: User) {
        let result: boolean;
        result = false;
        let role = JSON.parse(selectedroleobject);
        const values = user.Roles.split(',');
        values.forEach((v: string) => {
            if (v.trim() === role.role.trim()) {
                result = true;
            }
        })
        return result;
    }

    const handleRoleChange = (event: SelectChangeEvent<string>) => {
        setSelectedRoleObject(event.target.value.toString());
        let role = JSON.parse(event.target.value.toString());
        if (role != null && role.roleid > -1) {
            formik.handleChange(event.target.value.toString());

            loadShiftSetupsForRole(role.roleid);

            setRoleSelectedError(false);            
        }
    }

    const handleUnitChange = (event: SelectChangeEvent<string>) => {
        setSelectedUnitObject(event.target.value.toString());
        let unit = JSON.parse(event.target.value.toString());

        if (unit != null && unit.unitid > -1) {
            formik.handleChange(event.target.value.toString());
        }
    }

    const handleShiftChange = (event: SelectChangeEvent<string>) => {
        setSelectedShiftObject(event.target.value.toString());
        let shift = JSON.parse(event.target.value.toString());
        if (shift != null && shift.shiftid > -1) {
            //loadShiftSetupsForRole(selectedRole);

            getShiftSetupsForRole.forEach((s) => {
                if (s.shiftid == shift.shiftid) {
                    formik.values.startTime = new Date(s.shiftstarttime);
                    formik.values.endTime = new Date(s.shiftendtime);
                    setOriginalStartTime(s.shiftstarttime);
                    setOriginalEndTime(s.shiftendtime);
                }
            })
            formik.handleChange(event.target.value.toString());

            setShiftSelectedError(false);
        }
    }

    const handleClose = () => {
        setSelectedRoleObject('');
        setSelectedShiftObject('');
        setSelectedUnitObject('');
        setSelectedDays([]);
        scheduleStore.clearShiftInfo();
        formik.resetForm();
        onClose(false);
    };

    function areDatesEqual(date1: Date, date2: Date): boolean {
        // Convert both dates to the same timezone (e.g., UTC)
        const utcDate1 = Date.UTC(
            date1.getFullYear(),
            date1.getMonth(),
            date1.getDate(),
            date1.getHours(),
            date1.getMinutes(),
            date1.getSeconds()
        );
        const utcDate2 = Date.UTC(
            date2.getFullYear(),
            date2.getMonth(),
            date2.getDate(),
            date2.getHours(),
            date2.getMinutes(),
            date2.getSeconds()
        );

        // Compare the UTC timestamps
        return utcDate1 === utcDate2;
    }

    function doesShiftEndNextDay(date1: Date, date2: Date): boolean {
        // Convert both dates to the same timezone (e.g., UTC)
        if (date2.getHours() < date1.getHours()) {
            return true;
        }
        else {
            return false;
        }
    }
    const formik = useFormik<FormValues>({
        initialValues: {
            selectedunitobject: '',
            startTime: new Date(),
            endTime: new Date(),
            assignedUsers: [],
        },
        // validationSchema: ValidSchema,
        // validateOnBlur: true,
        onSubmit: (values) => {
            setError('');
            let co = window.localStorage.getItem('cono');
            if (!co) {
                return;
            }
            let haserrors = false;
            if (selectedroleobject.trim() === "") {
                setRoleSelectedError(true);
                haserrors = true;
            }
            if (selectedshiftobject.trim() === "") {
                setShiftSelectedError(true);
                haserrors = true;
            }
            if(selectedDays.length <= 0){
                setDaysSelectedError(true);
                haserrors = true;
            }
            if(formik.values.assignedUsers.length <= 0){
                setUsersSelectedError(true);
                haserrors = true;
            }

            if(haserrors){
                return;
            }
            let shift = JSON.parse(selectedshiftobject);
            let role = { roleid: -1 };
            if (selectedroleobject.length > 1) {
                role = JSON.parse(selectedroleobject);
            }
            let unit = { unitid: -1 };
            if (selectedunitobject.length > 1) {
                unit = JSON.parse(selectedunitobject);
            }
            //alert(JSON.stringify(formik.values, null, 2));
            const shiftdatewithouttime = new Date(props.sundaydate.getFullYear(), props.sundaydate.getMonth(), props.sundaydate.getDate());
            const endshiftdatewithouttime = new Date(props.sundaydate.getFullYear(), props.sundaydate.getMonth(), props.sundaydate.getDate());
            if (shift.shiftendsnextday) {
                endshiftdatewithouttime.setDate(endshiftdatewithouttime.getDate() + 1);
            }
            let user: BatchAddUsersToShiftsDTO = {
                cono: co, usernames: formik.values.assignedUsers, roleid: role.roleid, sundaydate: props.sundaydate,
                shiftstarttime: new Date(new Date(shiftdatewithouttime).setHours(new Date(values.startTime).getHours(), new Date(values.startTime).getMinutes(), 0, 0)).toLocaleString(),
                shiftendtime: new Date(new Date(endshiftdatewithouttime).setHours(new Date(values.endTime).getHours(), new Date(values.endTime).getMinutes(), 0, 0)).toLocaleString(),
                shiftid: shift.shiftid, shiftendsnextday: shift.shiftendsnextday, partialshift: false,
                unitid: unit.unitid, dows: selectedDays
            }
            
            scheduleStore.putBatchAddUserShiftsToSchedule(user).then(() => {
                formik.resetForm();
                onClose(true);
            })
        }
    });

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='md'>
                <DialogTitle textAlign={'center'}>Add User Shifts To Schedule</DialogTitle>
                <DialogContentText>
                    <Typography textAlign={'center'} marginBottom={1} fontSize={13}>For The Week Of {props.sundaydate.toDateString()}</Typography>
                </DialogContentText>
                {loadingEdit ?
                    <>
                        <DialogContent dividers={true}>
                            <LinearProgress />
                        </DialogContent>
                    </>
                    :
                    <form onSubmit={formik.handleSubmit} >
                        <DialogContent dividers={true}>
                            <Stack alignContent={'center'} alignItems={'center'}>
                                <FormControl>
                                    <InputLabel id="Role-labelid">Role</InputLabel>
                                    <Select
                                        labelId="Role-labelid"
                                        label="Role"
                                        sx={{ display: 'flex', width: 300, fontSize: 13 }}
                                        value={selectedroleobject}
                                        error={roleSelectedError}
                                        onChange={handleRoleChange}>
                                        {getCompanyRoles.map((role) => (
                                            <MenuItem key={role.roleid} value={JSON.stringify({ roleid: role.roleid, role: role.role })}>
                                                {role.role}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {roleSelectedError &&
                                    <Typography style={{ marginBottom: 1, fontSize:11 }} color='red'>Role Is Required</Typography>
                                }
                                <FormControl sx={{ marginTop: 1 }}>
                                    <InputLabel id="shift-labelid">Shift</InputLabel>
                                    <Select
                                        labelId="shift-labelid"
                                        label="Shift"
                                        sx={{ display: 'flex', width: 300, fontSize: 13 }}
                                        value={selectedshiftobject}
                                        error={shiftSelectedError}
                                        onChange={handleShiftChange}>
                                        {getShiftSetupsForRole.map((shift) => (
                                            <MenuItem key={shift.shiftid} value={JSON.stringify({ shiftid: shift.shiftid, shiftendsnextday: shift.shiftendsnextday })}>
                                                {shift.descr}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {shiftSelectedError &&
                                    <Typography style={{ marginBottom: 1, fontSize:11 }} color='red'>Shift Is Required</Typography>
                                }
                                {selectedshiftobject != '' ?
                                    <Stack direction={'row'}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                name="startTime"
                                                label="Start Time"
                                                minutesStep={15}
                                                value={dayjs(formik.values.startTime)}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue("startTime", dayjs(newValue));
                                                }}
                                                formatDensity='dense'
                                                slotProps={{ textField: { size: 'small' } }}
                                                sx={{ margin: 1, width: 140, height: 50 }}
                                            />
                                        </LocalizationProvider>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                name="endTime"
                                                label="End Time"
                                                minutesStep={15}
                                                value={dayjs(formik.values.endTime)}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue("endTime", dayjs(newValue));
                                                }}
                                                formatDensity='dense'
                                                slotProps={{ textField: { size: 'small' } }}
                                                sx={{ margin: 1, width: 140, height: 50 }}
                                            />
                                        </LocalizationProvider>
                                    </Stack>
                                    : <></>}
                                <FormControl sx={{ margin: 1 }}>
                                    <InputLabel id="weekday-select-label">Day(s)</InputLabel>
                                    <Select sx={{ display: 'flex', width: 300, fontSize: 13 }}
                                        label="Days"
                                        labelId="weekday-select-label"
                                        id="weekday-select"
                                        multiple
                                        value={selectedDays}
                                        error={daysSelectedError}
                                        onChange={handleChangeDOW}
                                        renderValue={(selected) => sortDays(selected).map(abbreviateDay).join(', ')}
                                    >
                                        {daysofweek.map((day) => (
                                            <MenuItem key={day} value={day}>
                                                <Checkbox sx={{ height: 15, width: 15, marginRight: 1, paddingTop: 1 }} checked={selectedDays.indexOf(day) > -1} />
                                                <ListItemText primary={<Typography variant="h5" style={{ fontSize: 11, marginTop: 1 }}>{day}</Typography>} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {daysSelectedError &&
                                    <Typography style={{ marginBottom: 1, fontSize:11 }} color='red'>Day(s) Are Required</Typography>
                                }
                                <FormControl sx={{ margin: 1 }}>
                                    <InputLabel id="role-label">User(s)</InputLabel>
                                    <Select sx={{ display: 'flex', width: 300, fontSize: 13 }}
                                        labelId="assigned-users-label"
                                        id="assignedUsers"
                                        name="assignedUsers"
                                        label='User'
                                        multiple
                                        error={usersSelectedError}
                                        value={formik.values.assignedUsers}
                                        MenuProps={MenuProps}
                                        onChange={formik.handleChange}
                                        // renderValue={(selected) => selected.join(', ')}
                                        renderValue={(selected) => (
                                            <Typography
                                                noWrap={false}
                                                sx={{
                                                    display: 'block',
                                                    whiteSpace: 'normal',
                                                    wordWrap: 'break-word',
                                                    fontSize: 11
                                                }}
                                            >
                                                {selected.join(', ')}
                                            </Typography>
                                        )}
                                    >
                                        {selectedroleobject != '' ? getAllUsers.filter(excludeRoles).map((user) => (
                                            <MenuItem key={user.username} value={user.username}>
                                                <Checkbox sx={{ height: 15, width: 15, marginRight: 1, paddingTop: 1 }} checked={formik.values.assignedUsers.indexOf(user.username) > -1} />
                                                <ListItemText primary={<Typography variant="h5" style={{ fontSize: 11, marginTop: 1 }}>{user.isagency ? '(a) ' : <></>}{user.lastname}, {user.firstname} - {user.username}</Typography>} />
                                            </MenuItem>
                                        )) : <></>}
                                    </Select>
                                </FormControl>
                                {usersSelectedError &&
                                    <Typography style={{ marginBottom: 1, fontSize:11 }} color='red'>User(s) Are Required</Typography>
                                }
                                <FormControl sx={{ margin: 1 }}>
                                    <InputLabel id="Unit-labelid">Unit</InputLabel>
                                    <Select
                                        labelId="Unit-labelid"
                                        label="Unit"
                                        sx={{ display: 'flex', width: 300, fontSize: 13 }}
                                        value={selectedunitobject}
                                        onChange={handleUnitChange}>
                                        {getUnitSetups.map((unit) => (
                                            <MenuItem key={unit.unitid} value={JSON.stringify({ unitid: unit.unitid, unitname: unit.unitname, unitshortname: unit.unitshortname })}>
                                                {unit.unitname}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </DialogContent>
                    </form>
                }
                <DialogActions>
                    <Box display="flex" justifyContent="end" width="100%">
                        <Box display="flex" justifyContent="end" sx={{ alignContent: 'end', alignItems: 'end', alignSelf: 'end' }}>
                            <Button variant='outlined' onClick={handleClose}>Cancel</Button>
                            <LoadingButton sx={{ marginLeft: 1 }} loading={loadingDetails} variant='contained' onClick={formik.submitForm}>Save Shift(s)</LoadingButton>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
})