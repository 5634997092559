import { TableContainer, styled, tableCellClasses, Paper, Table, TableHead, TableBody, TableCell, TableRow, CssBaseline, Divider, Stack, Tab, Tabs, IconButton, TextField, Box, Typography, LinearProgress, Fab, Tooltip, Button, Container } from "@mui/material";
import { observer } from "mobx-react-lite"
import React, { forwardRef, useEffect, useRef } from "react";
import { useStore } from "../../stores/store";
import CompanyRole from "../../models/CompanyRole";
import { format, setDate } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CurrentSchedule } from "../../models/Schedule/CurrentSchedule";
import AddIcon from '@mui/icons-material/Add';
import AddNewShiftDialog from "./AddNewShiftDialog";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddNewShiftToScheduleDialog from "./AddNewShiftToScheduleDialog";
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import { Margin, Padding } from "@mui/icons-material";
import InfoIcon from '@mui/icons-material/Info';
import AddUserToSchedule from "./AddUserToSchedule";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { useParams } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#8a8a8a ',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#ebebeb ',
    },
}));
function getNextSunday() {
    console.log('get previous sunday');
    let dt = new Date();
    switch (dt.getDay()) {
        case 0:
            break;
        case 1:
            dt.setDate(dt.getDate() + 6);
            break;
        case 2:
            dt.setDate(dt.getDate() + 5);
            break;
        case 3:
            dt.setDate(dt.getDate() + 4);
            break;
        case 4:
            dt.setDate(dt.getDate() + 3);
            break;
        case 5:
            dt.setDate(dt.getDate() + 2);
            break;
        case 6:
            dt.setDate(dt.getDate() + 1);
            break;
    }

    dt.setDate(dt.getDate() - 7);
    return dt;
}
const getFontSize = () => {
    return 13;
}
function isDateInSelectedWeek(date1: Date, sunday: Date): boolean {
    const date1WithoutTime = new Date(new Date(date1).getFullYear(), new Date(date1).getMonth(), new Date(date1).getDate());
    const sun = new Date(new Date(sunday).getFullYear(), new Date(sunday).getMonth(), new Date(sunday).getDate());
    const nextsun = new Date(sun.getDate() + 7);

    let result = false;

    if (date1WithoutTime >= sun && date1WithoutTime <= nextsun) {
        console.log('cyaahompare ' + date1WithoutTime + ' and ' + sun)
        result = true;
    }
    return result;
}
function compareDatesIgnoringTime(date1: Date, date2: Date): boolean {
    const date1WithoutTime = new Date(new Date(date1).getFullYear(), new Date(date1).getMonth(), new Date(date1).getDate());
    const date2WithoutTime = new Date(new Date(date2).getFullYear(), new Date(date2).getMonth(), new Date(date2).getDate());


    return date1WithoutTime.getTime() === date2WithoutTime.getTime();
}
function countHoursBetweenDates(startDate: Date, endDate: Date): number {
    const timeDiff = endDate.getTime() - startDate.getTime();
    let hoursDiff = timeDiff / (1000 * 60 * 60);
    if (endDate < startDate) {//exception for overnight shifts where the end time might be midnight
        hoursDiff = (24 - Math.abs(Math.round(hoursDiff)));
    }
    return Math.abs(Math.round(hoursDiff));
}
function convertToDate(dateString: string | undefined): Date {
    if (dateString == undefined) {
        return new Date();
    }
    // Check if the input string matches the expected format
    if (!/^\d{8}$/.test(dateString)) {
        return new Date();
    }

    const month = parseInt(dateString.slice(0, 2), 10) - 1; // Months are 0-indexed in JavaScript
    const day = parseInt(dateString.slice(2, 4), 10);
    const year = parseInt(dateString.slice(4), 10);

    const date = new Date(year, month, day);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        throw new Error("Invalid date.");
    }

    return date;
}
export default observer(function NewScheduleMainPrintable() {
//const NewScheduleMainPrintable = () => {
    const { userStore, scheduleStore } = useStore();
    const { getCompanyRoles, loadCompanyRoles } = userStore;
    const { loading, getCurrentSchedule, loadCurrentSchedule } = scheduleStore;
    const [selectedTotals, setSelectedTotals] = React.useState(0);
    const [compactMode, setCompactMode] = React.useState(true);
    const [currentCensus, setCurrentCensus] = React.useState([110, 110, 110, 110, 110, 110, 110]);
    const [openAddNewShift, setOpenAddNewShift] = React.useState(false);
    const [openAddUserToSchedule, setOpenAddUserToSchedule] = React.useState(false);
    const [dorefresh, setDoRefresh] = React.useState(false);
    const [refreshKey, setRefreshKey] = React.useState(0);
    const [selectedUser, setSelectedUser] = React.useState('');
    const [selectedName, setSelectedName] = React.useState('');
    const [selectedRole, setSelectedRole] = React.useState('');
    const [selectedUnit, setSelectedUnit] = React.useState('');
    const [selectedShift, setSelectedShift] = React.useState('');
    const [selectedScheduleid, setSelectedScheduleid] = React.useState(-1); //used to edit a scheduled shift
    const [isEditingShift, setIsEditingShift] = React.useState(false);
    const [selectedShiftDate, setSelectedShiftDate] = React.useState(new Date());
    const [selectedStartTime, setSelectedStartTime] = React.useState(new Date());
    const [selectedEndTime, setSelectedEndTime] = React.useState(new Date());

    // const [ rolename, setRoleName] = React.useState('NS');
    //const [ selectedroleid, setSelecteRoleid] = React.useState(1);


    const { rolename } = useParams<{ rolename: string }>();
    const { selectedroleid } = useParams<{ selectedroleid: string }>();
    const { selectedSundayString } = useParams<{ selectedSundayString: string }>();
    const [selectedSunday, setSelectedSunday] = React.useState(convertToDate(selectedSundayString));

    useEffect(() => {
        document.title = 'Weekly Schedule';
        async function fetchData() {
            loadCurrentSchedule(format(selectedSunday, 'MMddyyyy'));
        }
        console.log('print role id ' + selectedroleid);
        fetchData();
    }, []);

    const daysofweek: string[] = [
        "SUNDAY",
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY"
    ];

    const handleOnCloseNewShiftDialog = (value: boolean) => {
        setOpenAddNewShift(false);
        if (value) {
            setRefreshKey(refreshKey + 1);
        }
    }
    const handleOnCloseAddUserDialog = (value: boolean) => {
        setOpenAddUserToSchedule(false);
        if (value) {
            setRefreshKey(refreshKey + 1);
        }
    }

    const handleUpdateCensus = (index: number, newValue: number) => {
        setCurrentCensus((prev) => {
            const updated = [...prev];
            updated[index] = newValue;
            return updated;
        });
    }
    const getFirstColumnWidth = () => {
        if (compactMode) {
            return 300;
        }
        else {
            return 300;
        }
    }
    const getSecondColunWidth = () => {
        if (compactMode) {
            return 130;
        }
        else {
            return 130;
        }
    }
    const getCombinedColumn = () => {
        let i: number = 0;
        i = getFirstColumnWidth() + getSecondColunWidth();
        console.log('width ' + i);
        return i;
    }
    const handleChangeTotals = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTotals(newValue);
    };
    const tabColorByRole = (r: number) => {
        if (r === 0) {
            return '#D18C19';
        }
        if (r === 1) {
            return '#1976D2';
        }
        else if (r === 2) {
            return '#3DD119';
        }
        else if (r === 3) {
            return '#D61AB0';
        }
        else if (r === 4) {
            return '#4E7EAD';
        }
        else {
            return '#1976d2';
        }
    }
    function excludeUsersThatDontHaveShiftInWeek(sched: CurrentSchedule) {
        console.log('checking shiftsd ' + sched.shifts.length);
        let result = false;
        let nextSunday = new Date(selectedSunday);
        let thissunday = new Date(selectedSunday);
        thissunday.setHours(0, 0, 0, 0);
        nextSunday.setDate(nextSunday.getDate() + 6);
        sched.shifts.forEach(s => {
            // if (sched.username = 'colby.breidenstein@taracares.com') {
            //     console.log('colby compare ' + sched.roleid + new Date(s.shiftdate) + ' and ' + new Date(thissunday) + ' - ' + s.scheduleid + (new Date(s.shiftdate) >= new Date(thissunday) && new Date(s.shiftdate) < new Date(nextSunday)));
            // }
            if (new Date(s.shiftdate) >= new Date(thissunday) && new Date(s.shiftdate) < new Date(nextSunday)) {
                // console.log('testyes compare ' + new Date(s.shiftdate) + ' and ' + new Date(nextSunday));
                result = true;
            }
        })
        return result;
    }
    function excludeAdministrationRole(role: CompanyRole) {
        return !role.isadministrationrole;
    }
    function excludeRoles(shift: any) {
        return true;


    }
    function getDoesUserHaveShiftThisDate(shifts: {
        shiftdate: Date
        shiftstarttimestring: string,
        shiftendtimestring: string,
        unitid: number,
        unitname: string,
        unitshortname: string,
        shiftid: number,
    }[], date: Date, username: string) {
        let result = false;
        shifts.forEach((shift) => {
            if (shift.shiftid < -1) { //hide the shifts that don't exist, for the 
                result = false;
                return result;
            }
            if (new Date(shift.shiftdate) >= new Date(date) && new Date(shift.shiftdate) <= new Date(date)) {
                if (shift.shiftstarttimestring === shift.shiftendtimestring) {
                    result = false;
                }
                else {
                    result = true;
                }
                return result;
            }

        })
        return result;
    }
    function getShiftsForUserForThisDateNew(shift: any, date: Date, schedule: CurrentSchedule) {
        let unitshort = '';
        let unit = '';
        let result = '';
        const date1WithoutTime = new Date(new Date(shift.shiftdate).getFullYear(), new Date(shift.shiftdate).getMonth(), new Date(shift.shiftdate).getDate());
        const date2WithoutTime = new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate());
        if (date1WithoutTime >= date2WithoutTime && date1WithoutTime <= date2WithoutTime) {
            if (shift.shiftstarttimestring != shift.shiftendtimestring) {
                result += shift.shiftstarttimestring + '-' + shift.shiftendtimestring;
                unitshort = shift.unitshortname;
                unit = shift.unitname;
            }
        }

        if (result.length < 1) {
            return (<></>)
        }

        return (
            <Stack direction={'column'} sx={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                <Typography sx={{ fontSize: 12 }}>{result}</Typography>
                {unit.length > 0 ?
                    <Typography sx={{ fontSize: 11 }}>{unit}
                    </Typography> : <></>}
            </Stack>
        )
    }

    const getDateFromDOW = (dow: string) => {
        let dt = new Date(selectedSunday);

        switch (dow.toUpperCase()) {
            case "SUNDAY":
                dt.setDate(dt.getDate());
                break;
            case "MONDAY":
                dt.setDate(dt.getDate() + 1);
                break;
            case "TUESDAY":
                dt.setDate(dt.getDate() + 2);
                break;
            case "WEDNESDAY":
                dt.setDate(dt.getDate() + 3);
                break;
            case "THURSDAY":
                dt.setDate(dt.getDate() + 4);
                break;
            case "FRIDAY":
                dt.setDate(dt.getDate() + 5);
                break;
            case "SATURDAY":
                dt.setDate(dt.getDate() + 6);
                break;
        }
        return dt.toDateString();
    }
    const getDateForDOW = (dow: number) => {
        //sends 0 for sunday, 1 for monday, 2 for tuesday etc..
        let newdate = new Date(selectedSunday);

        return newdate.setDate(selectedSunday.getDate() + dow);
    }
    const getDayDateFromView = (i: number) => {
        let result = new Date(selectedSunday);
        result.setDate(result.getDate() + i);
        return result;
    }

    function convertTimeStringToDate(timeString: string): Date {
        const [time, meridiem] = timeString.split(/(?=[ap])/);
        const [hours, minutes] = time.split(':').map(Number);

        const date = new Date();
        date.setHours(0, 0, 0, 0); // Reset to start of day

        let adjustedHours = hours;

        if (meridiem === 'p' && hours !== 12) {
            adjustedHours += 12;
        } else if (meridiem === 'a' && hours === 12) {
            adjustedHours = 0;
        }

        date.setHours(adjustedHours, minutes);

        return date;
    }
    const clickEditShiftInSchedule = (shiftdate: Date, scheid: number, shiftstart: Date, shiftend: Date, roleid: number, role: string, user: string, name: string, shift: string, unit: string) => {
        setIsEditingShift(true);
        setSelectedShift(shift);
        setSelectedScheduleid(scheid);
        setSelectedUnit(unit);
        setSelectedShiftDate(shiftdate);
        setSelectedStartTime(new Date(shiftstart));
        setSelectedEndTime(new Date(shiftend));
        setSelectedRole(role);
        setSelectedUser(user);
        setSelectedName(name);
        setOpenAddNewShift(true);
    }
    const clickAddNewShiftToSchedule = (shiftdate: Date, roleid: number, role: string, user: string, name: string) => {
        setIsEditingShift(false);
        setSelectedShiftDate(shiftdate);
        setSelectedUnit('');
        setSelectedShift('');
        setSelectedRole(role);
        setSelectedUser(user);
        setSelectedName(name);
        setOpenAddNewShift(true);
    }

    return (
        <div className="print-container">
            <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} divider={<Divider orientation="horizontal" flexItem />}>
                <Box sx={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                }}>

                    <Stack direction={'row'} sx={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                        <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{rolename} - Weekly Schedule {new Date(selectedSunday).toDateString()}</Typography>
                        <Box flexGrow={1} />
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker value={dayjs(selectedSunday)}
                                sx={{ fontSize: 13, marginTop: 2, marginBottom: 1, alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                label="Week Of (Sunday)"
                                shouldDisableDate={isWeekend}
                                slotProps={{ textField: { size: 'small' } }}
                                onChange={(newValue) => {
                                    if (newValue === null) {
                                        return;
                                    }
                                    setSelectedSunday(newValue.toDate());
                                }} />
                        </LocalizationProvider> */}
                    </Stack>
                </Box>
                {/* <CssBaseline /> */}
                <TableContainer component={Paper} sx={{ margin: 1 }}>
                    <Table size='small'>
                        <TableHead>
                            <StyledTableRow key="100">
                                <StyledTableCell width={200} align="center">USER</StyledTableCell>
                                <StyledTableCell align="center">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            SUNDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            {format(getDateForDOW(0), 'MM/dd/yy')}
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            MONDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            {format(getDateForDOW(1), 'MM/dd/yy')}
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center" >
                                    <Stack>
                                        <Typography fontSize={14}>
                                            TUESDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            {format(getDateForDOW(2), 'MM/dd/yy')}
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center" >
                                    <Stack>
                                        <Typography fontSize={14}>
                                            WEDNESDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            {format(getDateForDOW(3), 'MM/dd/yy')}
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            THURSDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            {format(getDateForDOW(4), 'MM/dd/yy')}
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            FRIDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            {format(getDateForDOW(5), 'MM/dd/yy')}
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            SATURDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            {format(getDateForDOW(6), 'MM/dd/yy')}
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {getCurrentSchedule.sort((a, b) => {                            
                                if (a.isagency !== b.isagency) {
                                    return a.isagency ? -1 : 1;
                                }
                                return a.lastname.localeCompare(b.lastname);
                            }).filter(excludeRoles).filter(excludeUsersThatDontHaveShiftInWeek).map((schedule: CurrentSchedule) => (
                                <StyledTableRow key={schedule.username + schedule.roleid} >
                                    <StyledTableCell width={200} key={schedule.username + schedule.roleid} align='center' sx={{ fontWeight: 'bold' }}>
                                        {schedule.isagency ?
                                            <Tooltip title="Agency User">
                                                <Typography sx={{ maxWidth: 200, textAlign: 'start', fontSize: 11, width: getFirstColumnWidth() }}>
                                                    (Agency) {schedule.name}
                                                </Typography></Tooltip>
                                            :
                                            <Typography sx={{ maxWidth: 200, textAlign: 'start', fontSize: 11, width: getFirstColumnWidth() }}>
                                                {schedule.name}
                                            </Typography>}
                                    </StyledTableCell>
                                    {Array.from(daysofweek).map((day, dayindex) => (
                                        getDoesUserHaveShiftThisDate(schedule.shifts, new Date(getDateFromDOW(day)), schedule.username) ?
                                            <StyledTableCell key={dayindex + schedule.username} align='center'>
                                                <Stack direction='column' sx={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                                    {Array.from(schedule.shifts).map((shift) =>
                                                        getShiftsForUserForThisDateNew(shift, new Date(getDateFromDOW(day)), schedule)
                                                    )}
                                                </Stack>
                                            </StyledTableCell>
                                            :
                                            <StyledTableCell key={dayindex + schedule.username} align='center' sx={{ fontWeight: 'bold' }}>
                                                <></>
                                            </StyledTableCell>
                                    ))}
                                    {/* <StyledTableCell width={80} key={-4} align='center' sx={{ fontWeight: 'bold' }}>
                                        <Typography sx={{ fontSize: 11 }}>
                                            {getTotalHoursForUserAndRole(schedule)}
                                        </Typography>
                                    </StyledTableCell> */}
                                </StyledTableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </div>
    )
})

// export default NewScheduleMainPrintable;