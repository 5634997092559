import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Button, Stack, Alert, Snackbar } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { UserFormValuesModel } from '../../models/User';
import { useStore } from '../../stores/store';
import { useNavigate } from "react-router-dom";
import { Link as Link1 } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import * as Yup from "yup";
import { UserConoAndPhone } from '../../models/UserConoAndPhone';

export default observer(function ViewMyShifts() {
    const { userStore } = useStore();
    const { loading } = userStore;
    const [ showTextConfirmation, setShowTextConfirmation ] = React.useState(false);

    const [error, setError] = React.useState('');

    useEffect(() => {
        document.title = 'View My Shifts';
    }, []);

    const handleCloseSnack = () =>{
        setShowTextConfirmation(false);
    }

    const ValidSchema = Yup.object().shape({
        phone: Yup.string().trim(),
            //.required("Phone Number Is Required"),
    });

    const formik = useFormik({
        initialValues: { phone: '', secret: '' },
        onSubmit: (values) => {
            setError('');
            let u: UserConoAndPhone = { phone: values.phone, secret: 'zxcui7asd1' };
            console.log('try ' + u.phone);
            userStore.sendviewshiftlink(u).catch(error => setError('Phone Number is invalid or not found')).then((response) => {
                if (response === false) {
                    console.log('invalid phone number');
                    setError('Phone Number is invalid or not found');
                } else {
                    setError('');
                    setShowTextConfirmation(true);
                    formik.resetForm({
                        values: { phone: '', secret: '' }
                    });
                }
            });
        },
        // validationSchema: ValidSchema,
        validateOnBlur: false,
        validateOnChange: false,
    });

    // const handleChange = (event: { target: { value: any; }; }) => {
    //     const {
    //         target: { value },
    //     } = event;
    // };

    const handleChange = () => {
        
    };

    return (
        <>
            {userStore.isLoggedIn ? (
                <Button sx={{ margin: 1, borderRadius:5 }} variant='contained' size='large' component={Link1} to="/home">HOME PAGE</Button>
            ) : (
                <>
                    <form onSubmit={formik.handleSubmit}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                formik.handleSubmit();
                            }
                        }}>
                        <Stack direction="column" justifyContent="left" alignItems="center">
                            <Typography sx={{ margin: 1 }} component="h1" variant="h5">Enter Your Phone Number</Typography>
                            <TextField sx={{ margin: 1 }} name='phone' placeholder='Phone Number' autoComplete='off' value={formik.values.phone} onChange={formik.handleChange}></TextField>
                            {/* <ErrorMessage
                            name='error' render={() => <Typography style={{marginBottom: 10}} color='red'>{formik.initialValues.error} </Typography>}
                            /> */}
                            <Typography style={{ marginBottom: 10 }} color='red'>{error}</Typography>
                            <Typography sx={{ margin: 1 }}>This will send a text message with a link to view your schedule and any open shifts</Typography>
                            {/* <Link href="/forgotpassword">Forgot Password?</Link> */}
                            <LoadingButton sx={{ margin: 1, borderRadius:5 }} loading={loading} variant='contained' size='large' onClick={formik.submitForm}>SEND LINK</LoadingButton>
                        </Stack>
                    </form>
                    <Snackbar open={showTextConfirmation} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ width: "100%" }} autoHideDuration={5000} onClose={handleCloseSnack}>
                        <Alert onClose={handleCloseSnack} severity="success" variant="filled" sx={{ fontSize: 24 }}>
                            Text Message Sent!
                        </Alert>
                    </Snackbar>
                </>
            )}
        </>
    )
})