import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { EmailAndTextUsage } from '../models/EmailAndTextUsage';
import NewShiftAlertModel from '../models/NewShiftAlert';
import { OpenShift } from '../models/OpenShift';
import { OpenShiftResponse } from '../models/OpenShiftReponse';
import { SendShiftAlertForRoleModel } from '../models/SendShiftAlertForRole';
import { SentShiftFromHistory } from '../models/SentShiftFromHistory';
import { ShiftAlertInfoByID } from '../models/ShiftAlertInfoByID';
import { ShiftAlertResponse } from '../models/ShiftAlertResponse';
import { ShiftAlertStopResponses } from '../models/ShiftAlertStopResponses';
import { ShiftInfo } from '../models/ShiftInfo';
import { ShiftInfoByKeyModel } from '../models/ShiftInfoByKeyModel';
import { ShiftSetup } from '../models/ShiftSetup';
import ShiftsInfoByKeyModel from '../models/ShiftsInfoByKey';
import { UserShiftResponse } from '../models/UserShiftResponse';
import { UserShiftRecommendation } from '../models/UserShiftRecommendation';
import { ShiftAlertForSingleUser } from '../models/ShiftAlertForSingleUser';
import { OpenShiftForNewKey } from '../models/OpenShiftForNewKey';
import { OpenShiftInfoForNewKey } from '../models/OpenShiftInfoForNewKey';

export default class ShiftStore {
    shiftRegistry = new Map<string, OpenShift>();
    shiftsByRole = new Map<string, OpenShift>();
    shiftsByRoleInfo: ShiftsInfoByKeyModel | undefined = undefined;
    shiftSentHistory = new Map<string, SentShiftFromHistory>();
    shiftSetupRegistry = new Map<number, ShiftSetup>();
    newOpensShiftsByKey = new Map<number, OpenShiftForNewKey>();
    shiftInfo: ShiftAlertInfoByID[] | undefined = undefined;
    newShiftInfo: OpenShiftInfoForNewKey[] | undefined = undefined;
    shiftResponses: ShiftAlertResponse[] | undefined = undefined;
    shiftUserRecommendations = new Map<string, UserShiftRecommendation>();
    allShiftResponses = new Map<string, ShiftAlertResponse>();
    shiftClaimOrDeny: ShiftInfoByKeyModel | undefined = undefined;
    textAndEmailUsage = new Map<string, EmailAndTextUsage>();
    loading = false;
    savingShiftAlert = false;
    sendingShiftAlerts = false;
    claimShiftError: string | undefined = undefined;

    constructor() {
        makeAutoObservable(this);
    }

    loadOpenShiftInfoForNewKey = async (key: string) => {
        const info = await agent.Shifts.getopenshiftsinfobynewkey(key);
        runInAction(() => {
            this.newShiftInfo = info;
            this.loading = false;
        })
    }

    loadOpenShiftsForNewKey = async (key: string) => {
        try {
            this.loading = true;
            const shifts = await agent.Shifts.getopenshiftsfornewkey(key);
            shifts.forEach(s => {
                this.setOpenShiftNewKey(s);
            })
        } catch (error) {
            console.log(error);
            this.loading = false;
        }
        this.loading = false;
    }
    private setOpenShiftNewKey = (shift: OpenShiftForNewKey) => {
        this.newOpensShiftsByKey.set(shift.shiftalertid, shift);
    }
    get getAllOpenShiftsForNewKey() {
        return Array.from(this.newOpensShiftsByKey.values());
    }

    loadShifts = async () => {
        try {
            const cono = window.localStorage.getItem('cono');
            if (!cono) {
                return
            }
            runInAction(() => {
                this.loading = true;
            })

            const shifts = await agent.Shifts.list(cono);
            shifts.forEach(s => {
                this.setShift(s);
            })

            runInAction(() => {
                this.allShiftResponses = new Map<string, ShiftAlertResponse>()
            })
            const responses = await agent.Shifts.allshiftresponses(cono);
            responses.forEach(r => {
                this.setResponseAll(r);
            })

            runInAction(() => {
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            this.loading = false;
        }
    }
    private setShift = (shift: OpenShift) => {
        this.shiftRegistry.set(shift.shiftalertid.toString(), shift);
    }

    loadShiftForRoleByKey = async (cono: string, key: string) => {
        try {
            runInAction(() => {
                this.loading = true;
            })

            const info = await agent.Shifts.shiftsinfoforrolebykey(cono, key);

            runInAction(() => {
                this.shiftsByRoleInfo = info;
            })

            const shifts = await agent.Shifts.shiftsforrolebykey(cono, key);
            console.log('got shifts ' + shifts.length);
            shifts.forEach(s => {
                this.setShiftForRole(s);
            })
            runInAction(() => {
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }

    }
    private setShiftForRole = (shift: OpenShift) => {
        this.shiftsByRole.set(shift.shiftalertid.toString(), shift);
    }

    loadUserRecommendationsForShifts = async (from: string, thru: string) => {
        try {
            const cono = window.localStorage.getItem('cono');
            if (!cono) {
                return
            }
            runInAction(() => {
                this.loading = true;
            })

            const shifts = await agent.Shifts.getuserrecommendationsforshifts(cono, from, thru);
            shifts.forEach(s => {
                this.setUserRecommendationForShift(s);
            })

            runInAction(() => {
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
    private setUserRecommendationForShift(user: UserShiftRecommendation) {
        this.shiftUserRecommendations.set(user.username + user.shiftalertid.toString(), user);
    }
    get getUserRecommendations() {
        return Array.from(this.shiftUserRecommendations.values());
    }

    resendShiftAlert = async (shift: ShiftInfo) => {
        // const shift: ShiftInfo = { cono: "1", shiftid: 1 };
        // console.log("try to resend alert");
        await agent.Shifts.resendshiftalert(shift);
    }

    sendShiftAlerttoOneUser = async (shift: ShiftAlertForSingleUser) => {
        // const shift: ShiftInfo = { cono: "1", shiftid: 1 };
        // console.log("try to resend alert");
        await agent.Shifts.sendshiftalerttooneuser(shift);
    }

    removeUserShiftResponse = async (shift: UserShiftResponse) => {
        // const shift: ShiftInfo = { cono: "1", shiftid: 1 };
        // console.log("try to resend alert");
        await agent.Shifts.removeusershiftresponse(shift);
    }

    putShiftAlertStopResponses = async (response: ShiftAlertStopResponses) => {
        await agent.Shifts.putshiftalertstopresponses(response);
    }


    loadShiftSentHistory = async (shiftalertid: string) => {
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }
        runInAction(() => {
            this.loading = true;
        })
        try {
            this.shiftSentHistory.clear();
            const info = await agent.Shifts.getshiftalertinfobyid(cono, shiftalertid);
            const history = await agent.Shifts.shiftaltersenthistory(cono, shiftalertid);
            history.forEach(s => {
                this.setHistory(s);
            })

            runInAction(() => {
                this.shiftInfo = info;
                this.loading = false;
            })
        } catch (error) {
            console.log('error getting shifts' + error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
    private setHistory = (shift: SentShiftFromHistory) => {
        this.shiftSentHistory.set(shift.datesent.toString(), shift);
    }

    private setResponseAll = (res: ShiftAlertResponse) => {
        //console.log("all " + res.responsedate);
        this.allShiftResponses.set(res.responsedate.toString(), res);
    }

    get getAllShifts() {
        return Array.from(this.shiftRegistry.values());
    }

    get getAllShiftsForSchedulerView() {
        const newArray = Array.from(this.shiftRegistry.values()).map(item => ({
            event_id: item.shiftalertid, title: item.roles + ' - ' + item.descr + ' - ' + item.shiftdescr + ' (' + item.RemainingOpenShifts + '/' + item.numofopenings + ')',
            start: new Date(new Date(item.shiftdate).setHours(0, 0, 0, 0)),
            end: new Date(new Date(item.shiftdate).setHours(0, 0, 0, 0)),
            disabled: false, admin: 1, color: this.getSchedulerColorByRoleid(item.roleid),
            starttime: new Date(item.shiftstarttime),
            endtime: new Date(item.shiftendtime),
            stopresponses: item.stopresponses,
            shiftdate: item.shiftdate,
            roleid: item.roleid,
            shiftendsnextday: item.shiftendsnextday,
        }))
        newArray.forEach(d => {
            d.start = new Date(new Date(d.start).setHours(d.starttime.getHours(), d.starttime.getMinutes(), 0, 0));
            if (d.shiftendsnextday) {
                let newdate = new Date(d.end);
                newdate.setDate(newdate.getDate() + 1);
                console.log(newdate);
                d.end = new Date(new Date(newdate).setHours(d.endtime.getHours(), d.endtime.getMinutes(), 0, 0));
            }
            else {
                d.end = new Date(new Date(d.end).setHours(d.endtime.getHours(), d.endtime.getMinutes(), 0, 0));
            }
        })
        return newArray;
    }

    get getAllShiftsForRole() {
        return Array.from(this.shiftsByRole.values());
    }

    get getUsersShiftsByKeyForSchedulerView() {
        const newArray = Array.from(this.shiftsByRole.values()).map(item => ({
            event_id: item.shiftalertid, title: item.Roles + ' - ' + item.descr + ' - ' + item.shiftdescr,
            // start: new Date(new Date(item.shiftdate).getFullYear(),new Date(item.shiftdate).getMonth(),            
            // new Date(item.shiftdate).getDay(),new Date(item.shiftstarttime).getHours(),new Date(item.shiftstarttime).getMinutes()),
            start: new Date(new Date(item.shiftdate).setHours(0, 0, 0, 0)),
            // end: new Date(new Date(item.shiftdate).getFullYear(),new Date(item.shiftdate).getMonth(),
            // new Date(item.shiftdate).getDay(),new Date(item.shiftendtime).getHours(),new Date(item.shiftendtime).getMinutes()),
            end: new Date(new Date(item.shiftdate).setHours(0, 0, 0, 0)),
            disabled: false, admin: 1, color: this.getSchedulerColorByRoleid(item.roleid),
            starttime: new Date(item.shiftstarttime),
            endtime: new Date(item.shiftendtime),
        }))
        newArray.forEach(d => {
            d.start = new Date(new Date(d.start).setHours(d.starttime.getHours(), d.starttime.getMinutes(), 0, 0));
            d.end = new Date(new Date(d.end).setHours(d.endtime.getHours(), d.endtime.getMinutes(), 0, 0));
        })
        return newArray;
    }
    private getSchedulerColorByRole = (role: string) => {
        console.log('role is ' + role);
        if (role === 'CNA') {
            return '#1976d2';
        }
        if (role === 'Nurse') {
            return '#3DD119';
        }
        else {
            return '#1976d2';
        }
    }
    private getSchedulerColorByRoleid = (roleid: number) => {
        if (roleid === 0) {
            return '#D18C19';
        }
        if (roleid === 1) {
            return '#1976D2';
        }
        else if (roleid === 2) {
            return '#3DD119';
        }
        else if (roleid === 3) {
            return '#D61AB0';
        }
        else if (roleid === 4) {
            return '#4E7EAD';
        }
        else {
            return '#1976d2';
        }
    }
    private getSchedulerColorByStatus = (status: number) => {
        if (status === 0) {
            return '#1976d2';
        }
        if (status === 1) {
            return '#ADD8E6';
        }
        else if (status === 2) {
            return '#FFA07A';
        }
        else if (status === 4) {
            return '#ADD8E6';
        }
        else {
            return '#ffb74d';
        }
    }

    get getAllShiftResponses() {
        return Array.from(this.allShiftResponses.values());
    }

    get getAllShiftHistory() {
        return Array.from(this.shiftSentHistory.values());
    }
    loadShiftSetupsForRole = async (roleid: number) => {
        console.log('try to get shifts by role' + roleid);
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }
        this.shiftSetupRegistry.clear();
        try {
            const shifts = await agent.Shifts.getshiftsetupsforrole(cono, roleid);
            shifts.forEach(s => {
                this.setShiftSetup(s);
            })

        } catch (error) {
            console.log('error getting shifts' + error);
        }
    }

    clearShiftSetupsForRole = async () => {
        this.shiftSetupRegistry.clear();
    }

    get getShiftSetupsForRole() {
        return Array.from(this.shiftSetupRegistry.values());
    }

    loadShiftSetups = async () => {
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }
        this.loading = true;
        try {
            this.shiftSetupRegistry.clear();
            const shifts = await agent.Shifts.getshiftsetups(cono);
            shifts.forEach(s => {
                this.setShiftSetup(s);
            })
            this.loading = false;
        } catch (error) {
            console.log('error getting shifts' + error);
            this.loading = false;
        }

        this.loading = false;
    }
    private setShiftSetup = (shift: ShiftSetup) => {
        this.shiftSetupRegistry.set(shift.row, shift);
    }
    get getShiftSetups() {
        return Array.from(this.shiftSetupRegistry.values());
    }

    loadShiftAlertInfoById = async (cono: string, shiftalertid: string) => {
        try {
            this.loading = true;
            const info = await agent.Shifts.getshiftalertinfobyid(cono, shiftalertid);
            const responses = await agent.Shifts.viewshiftalertresponses(cono, shiftalertid);
            runInAction(() => {
                this.shiftInfo = info;
                this.shiftResponses = responses;
            })
            // alert(JSON.stringify(this.shiftInfo, null, 2));
            this.loading = false;
            return info;
        } catch (error) {
            console.log('its messed up ' + error);
            this.loading = false;
        }

    }

    getShiftInfoByKey = async (fac: string, key: string) => {
        try {
            console.log('trying to get info by key ');
            this.loading = true;
            const info = await agent.Shifts.shiftinfobykey(fac, key);
            runInAction(() => {
                this.shiftClaimOrDeny = info;
            })
            this.loading = false;
            return info;
        } catch (error) {
            console.log('its messed up ' + error);
            this.loading = false;
        }
    }

    loadTextEmailUsage = async (cono: string, from: string, thru: string) => {
        try {
            this.loading = true;
            const usage = await agent.Shifts.getemailandtextusage(cono, from, thru);
            usage.forEach(s => {
                this.setTextAndEmailUsage(s);
            })
            this.loading = false;
        } catch (error) {
            this.loading = false;
        }
    }
    private setTextAndEmailUsage = (info: EmailAndTextUsage) => {
        this.textAndEmailUsage.set(info.Cono, info);
    }
    get getTextAndEmailUsage() {
        return Array.from(this.textAndEmailUsage.values());
    }

    putOpenShiftResponse = async (response: OpenShiftResponse) => {
        try {
            let result = await agent.Shifts.putopenshiftresponse(response);
            return result.toString();
        }
        catch (error) {
            console.log('Error Sending Shift Response ' + error);
            //throw error;
        }
    }

    putShiftsByTemplate = async (shifts: string) => {
        try {
            let result = await agent.Shifts.putshiftsbytemplate(shifts);
        }
        catch (error) {
            console.log('Error creating shifts by template ' + error);
        }
    }

    createShiftAlert = async (shift: NewShiftAlertModel) => {
        console.log("try to create shift alert");
        this.savingShiftAlert = true;
        if (window.localStorage.getItem('user') == null) {
            return;
        }
        shift.createdbyusername = window.localStorage.getItem('user')!;
        shift.cono = window.localStorage.getItem('cono')!;
        try {
            await agent.Shifts.create(shift);
            this.savingShiftAlert = false;
        }
        catch (error) {
            console.log('Error Creating Shift Alert ' + error);
            this.savingShiftAlert = false;
        }
    }

    createAndSendKeysForRole = async (shift: SendShiftAlertForRoleModel) => {
        this.sendingShiftAlerts = true;
        shift.username = window.localStorage.getItem('user')!;
        console.log('try sending alerts for a role');
        try {
            await agent.Shifts.createkeysandsendalertforrole(shift);
            this.sendingShiftAlerts = false;
        } catch (error) {
            console.log('Error Sending Alerts By Role ' + error);
            this.sendingShiftAlerts = false;
        }
    }

    confirmShiftsNoMessage = async (jsonstring: string) => {
        this.sendingShiftAlerts = true;
        console.log('try sending shifts to be confirmed');
        try {
            await agent.Shifts.confirmshiftsnomessage(jsonstring);
            this.sendingShiftAlerts = false;
        } catch (error) {
            console.log('Error Sending shifts to be confirmed ' + error);
            this.sendingShiftAlerts = false;
        }
    }

    confirmShiftsSingleMessage = async (jsonstring: string) => {
        this.sendingShiftAlerts = true;
        console.log('try sending shifts to be confirmed');
        try {
            await agent.Shifts.confirmshiftssinglemessage(jsonstring);
            this.sendingShiftAlerts = false;
        } catch (error) {
            console.log('Error Sending shifts to be confirmed ' + error);
            this.sendingShiftAlerts = false;
        }
    }

    confirmShiftsMultipleMessages = async (jsonstring: string) => {
        this.sendingShiftAlerts = true;
        console.log('try sending shifts to be confirmed');
        try {
            await agent.Shifts.confirmshiftsmultiplemessages(jsonstring);
            this.sendingShiftAlerts = false;
        } catch (error) {
            console.log('Error Sending shifts to be confirmed ' + error);
            this.sendingShiftAlerts = false;
        }
    }
}