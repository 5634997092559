import { Tabs, Tab, Stack, Typography, Box, LinearProgress, Paper, Table, TableContainer, TableBody, Button, TableCell, TableRow } from "@mui/material"
import { observer } from "mobx-react-lite"
import React, { useEffect } from "react";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import QueueIcon from '@mui/icons-material/Queue';
import { useParams } from "react-router-dom";
import { Scheduler } from "@aldabil/react-scheduler";
import ShiftListForRole from "../shifts/ShiftListForRole";
import { useStore } from "../../stores/store";
import { UserShiftsByKey } from "../../models/UserShiftsByKey";
import { format, setDate } from 'date-fns';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { OpenShiftForNewKey } from "../../models/OpenShiftForNewKey";
import { OpenShiftResponse } from "../../models/OpenShiftReponse";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
    },
}));

const StyledTableInnerRow = styled(TableRow)(({ theme }) => ({
    root: {
        border: 1
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
}));

export default observer(function UserView() {
    const { userStore, shiftStore } = useStore();
    const { loading, loadUserShiftsByKey, getUsersShiftsByKeyForSchedulerView, userShiftsByKey } = userStore;
    const { loadOpenShiftsForNewKey, newOpensShiftsByKey, getAllOpenShiftsForNewKey, loadOpenShiftInfoForNewKey, newShiftInfo, loading: loadingShift } = shiftStore;
    const [selectedTab, setSelectedTab] = React.useState(0);
    const { tab } = useParams<{ tab: string }>();
    const { key } = useParams<{ key: string }>();
    const { shiftalertid } = useParams<{ shiftalertid: string }>();
    const { cono } = useParams<{ cono: string }>();
    let facList = new Map<string, string>();
    const [hideFac, setHideFac] = React.useState(false);
    const [showHighlightedShift, setShowHighlightedShift] = React.useState(false);
    const [shiftWasClaimed, setShiftWasClaimed] = React.useState(false);
    const [shiftWasDenied, setShiftWasDenied] = React.useState(false);
    const [shiftIsUnavailable, setShiftIsUnavailable] = React.useState(false);
    const [singleCompanyName, setSingleCompanyName] = React.useState('');

    useEffect(() => {
        const arrayUniqueByKey = Array.from(new Set(getAllOpenShiftsForNewKey.map((item: any) => item.companyname)))
                console.log('facs' + arrayUniqueByKey.length);
                if (arrayUniqueByKey.length > 1) {
                    setHideFac(false);
                    setSingleCompanyName('');
                }
                else {
                    setHideFac(true);
                    setSingleCompanyName(arrayUniqueByKey[0]);
                }
    },[getAllOpenShiftsForNewKey]);

    useEffect(() => {
        if (tab == "0") {
            setSelectedTab(0);
        }
        else {
            setSelectedTab(1);
        }
        if (key) {
            loadUserShiftsByKey(key);
            loadOpenShiftInfoForNewKey(key);
            loadOpenShiftsForNewKey(key).then(() => {
                const arrayUniqueByKey = Array.from(new Set(getAllOpenShiftsForNewKey.map((item: any) => item.companyname)))
                console.log('facs' + arrayUniqueByKey.length);
                if (arrayUniqueByKey.length > 1) {
                    setHideFac(false);
                    setSingleCompanyName('');
                }
                else {
                    setHideFac(true);
                    setSingleCompanyName(arrayUniqueByKey[0]);
                }
            });
        }
        if (shiftalertid) {
            setShowHighlightedShift(true);
        }
    }, []);
    const convertDateToTimeString = (date: Date): string => {
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'p' : 'a';

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        return `${hours}:${minutes}${ampm}`;
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    }
    function getNextScheduleShift() {
        let result = 'No Upcoming Shifts';
        if (userShiftsByKey == null) {
            result = 'No Upcoming Shifts';
        }
        else {
            let s: UserShiftsByKey = Array.from(userShiftsByKey.values())[0];
            var curr = new Date();
            curr.setDate(curr.getDate()+1);
            Array.from(userShiftsByKey.values()).forEach((shift) => {
                if (new Date(shift.shiftdate) >= curr) {
                    s = shift;
                    result = format(new Date(s.shiftdate), 'MM/dd/yy') + ' ' + convertDateToTimeString(new Date(s.shiftstarttime)) + '-' + convertDateToTimeString(new Date(s.shiftendtime));
                }
            })
            Array.from(userShiftsByKey.values()).forEach((shift) => {
                if (new Date(shift.shiftdate) >= curr && new Date(shift.shiftdate) < new Date(s.shiftdate)) {
                    s = shift;
                    result = format(new Date(s.shiftdate), 'MM/dd/yy') + ' ' + convertDateToTimeString(new Date(s.shiftstarttime)) + '-' + convertDateToTimeString(new Date(s.shiftendtime));
                }
            })
            // Array.from(userShiftsByKey.values()).filter(u => u.shiftdate > now).forEach((shift) => {
            //     console.log('comparing ' + shift.shiftdate);
            //     if (new Date(s.shiftdate) < new Date(shift.shiftdate)) {
            //         s = shift;
            //         result = new Date(s.shiftdate).toDateString();
            //     }
            // })
        }

        return result;
    }

    const handleClickAccept = (shift: OpenShiftForNewKey) => {
        if (newShiftInfo) {
            console.log("try to claim shift id " + shift.shiftalertid + newShiftInfo[0].username);
            shiftStore.putOpenShiftResponse(new OpenShiftResponse(shift.cono, shift.shiftalertid, newShiftInfo[0].username, true)).then((result) => {
                if (result === 'Shift Unavailable') {
                    setShiftIsUnavailable(true);
                }
                else {
                    setShiftWasClaimed(true);
                }
                if (key) {
                    loadOpenShiftsForNewKey(key)
                }
            });
        }
    }
    function excludeHighlightedShift(shift: OpenShiftForNewKey) {
        if (!showHighlightedShift || shiftalertid == undefined) {
            return true;
        }
        else {
            console.log(shift.shiftalertid + ' and ' + shiftalertid + ' also' + shift.cono + " andd " + cono);
            if (shift.shiftalertid == Number.parseInt(shiftalertid) && shift.cono == cono) {
                console.log('should be false');
                return false;
            }
            else {
                return true;
            }

        }
    }
    function onlyIncludeHighlightedShift(shift: OpenShiftForNewKey) {
        if (!showHighlightedShift || shiftalertid == undefined) {
            return false;
        }
        else {
            console.log(shift.shiftalertid + ' and ' + shiftalertid + ' also' + shift.cono + " andd " + cono);
            if (shift.shiftalertid == Number.parseInt(shiftalertid) && shift.cono == cono) {
                return true;
            }
            else {
                return false;
            }

        }
    }

    const handleClickDeny = (shift: OpenShiftForNewKey) => {
        if (newShiftInfo) {
            //console.log("try to claim shift id " + shift.shiftalertid + newShiftInfo[0].username);
            shiftStore.putOpenShiftResponse(new OpenShiftResponse(shift.cono, shift.shiftalertid, newShiftInfo[0].username, false)).then((result) => {
                if (result === 'Shift Unavailable') {
                    setShiftIsUnavailable(true);
                }
                else {
                    setShiftWasClaimed(true);
                }
                if (key) {
                    loadOpenShiftsForNewKey(key)
                }
            });
        }
    }

    return (
        <>
            <Tabs
                value={selectedTab}
                onChange={handleChange}
                indicatorColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
                TabIndicatorProps={{ style: { height: 3 } }}
            >
                <Tab label="My Schedule" value={0} icon={<CalendarMonthIcon />} style={{ fontWeight: 'bold' }} />
                <Tab label="Claim Open Shifts" value={1} icon={<QueueIcon />} style={{ fontWeight: 'bold' }} />
            </Tabs>
            {newShiftInfo && newShiftInfo[0] ?
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Stack direction={'row'} padding={1} marginTop={1} sx={{ alignSelf: 'center', border: 1, borderRadius: 2, borderColor: '#979797' }}>
                        <Typography style={{ fontWeight: 'bold', fontSize: 12 }}>User:</Typography>
                        <Typography style={{ fontSize: 12, marginLeft: 3 }}>{newShiftInfo[0].lastname + ', ' + newShiftInfo[0].firstname + ' (' + newShiftInfo[0].username + ')'}</Typography>
                    </Stack>
                </div>
                : <></>}
            {selectedTab == 0 ?
                <>
                    {loading ?
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <h1 style={{
                                        textAlign: 'center', fontSize: '14',
                                        fontWeight: 700,
                                        color: 'inherit', marginTop: 2, marginBottom: 0,
                                    }}>Loading Schedule...</h1>
                                    <Box sx={{ width: '100%' }}>
                                        <LinearProgress />
                                    </Box>
                                </Stack>
                            </div></>
                        :
                        <>
                        {getUsersShiftsByKeyForSchedulerView && getUsersShiftsByKeyForSchedulerView.length > 0 ?
                            <Stack>
                                <Stack direction={'row'} padding={1} margin={1} sx={{ alignSelf: 'center', border: 1, borderRadius: 2, borderColor: '#979797' }}>
                                    <Typography style={{ fontWeight: 'bold', fontSize: 12 }}>My Next Shift:</Typography>
                                    <Typography style={{ fontSize: 12, marginLeft: 3 }}>{getNextScheduleShift()}</Typography>
                                </Stack>
                                <Scheduler
                                    height={520}
                                    editable={false}
                                    deletable={false}
                                    view="month"
                                    day={null}
                                    agenda={false}
                                    week={{
                                        weekDays: [0, 1, 2, 3, 4, 5, 6],
                                        weekStartOn: 0,
                                        startHour: 0,
                                        endHour: 24,
                                        step: 120
                                    }}
                                    month={{
                                        weekDays: [0, 1, 2, 3, 4, 5, 6],
                                        weekStartOn: 0,
                                        startHour: 0,
                                        endHour: 24,
                                    }}
                                    events={getUsersShiftsByKeyForSchedulerView.filter(s => s.status == 4 || s.status == -1)} />
                            </Stack>
                            :
                            <>
                            <div className="p-2">No Shifts Or Invalid Key</div>
                            </>}
                            </>}
                </>
                :
                <div style={{ width: '100%', justifyContent: 'center' }}>
                    {loadingShift ?
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <h1 style={{
                                        textAlign: 'center', fontSize: '14',
                                        fontWeight: 700,
                                        color: 'inherit', marginTop: 2, marginBottom: 0,
                                    }}>Loading Open Shifts...</h1>
                                    <Box sx={{ width: '100%' }}>
                                        <LinearProgress />
                                    </Box>
                                </Stack>
                            </div></> :
                        <Stack style={{ marginBottom: 25, alignItems: 'center', justifyContent: 'center' }}>
                            {/* <FormControl sx={{ margin: 1 }}>
                            <InputLabel id="role-label">Company</InputLabel>
                            <Select
                                labelId="role-label"
                                name="roleid"
                                sx={{width:240}}
                                >
                                {Array.from(facList.values()).map((fac,facindex) => (
                                    <MenuItem key={fac} value={fac}>
                                        {fac}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}
                            {singleCompanyName && singleCompanyName.length > 2 ?
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Stack direction={'row'} padding={1} margin={1} sx={{ alignSelf: 'center', border: 1, borderRadius: 2, borderColor: '#979797' }}>
                                        <Typography style={{ fontWeight: 'bold', fontSize: 12 }}>Company:</Typography>
                                        <Typography style={{ fontSize: 12, marginLeft: 3 }}>{singleCompanyName}</Typography>
                                    </Stack>
                                </div> : <></>}
                            {shiftalertid && cono ?
                                <> <TableContainer component={Paper} sx={{ width: "max-content", alignItems: 'center', marginBottom: 5, marginTop: 1 }}>
                                    <Table size='small' aria-label="simple table">
                                        <TableBody>
                                            {getAllOpenShiftsForNewKey.filter(onlyIncludeHighlightedShift).map(shift =>
                                                <React.Fragment>
                                                    <TableRow sx={{ backgroundColor: '#1976d2' }} key={shift.shiftdate.toString() + shift.shiftstarttime.toString() + shift.shiftalertid.toString()}>
                                                        <TableCell sx={{ fontSize: 16, color: 'white' }} align="center">Shift</TableCell>
                                                        {/* <TableCell sx={{ fontSize: 16, color: 'white' }} align="center">Shift Time:</TableCell> */}
                                                        <TableCell sx={{ fontSize: 16, color: 'white' }} align="center">Role</TableCell>
                                                    </TableRow>
                                                    <TableRow key={shift.cono + shift.shiftalertid.toString() + 'roles'} sx={{ "& td": { border: 0 } }}>
                                                        <StyledTableCell align="center">{format(new Date(shift.shiftdate), 'MM/dd/yyyy')}<br />{format(new Date(shift.shiftstarttime), "hh:mm a")} - {format(new Date(shift.shiftendtime), "hh:mm a")}</StyledTableCell>
                                                        <StyledTableCell align="center">{shift.Roles}</StyledTableCell>
                                                        {/* <StyledTableCell align="center">{format(new Date(shift.shiftstarttime), "hh:mm a")} - {format(new Date(shift.shiftendtime), "hh:mm a")}</StyledTableCell> */}
                                                        {/* <StyledTableCell align="center">}</StyledTableCell> */}
                                                        {/* <StyledTableCell align="center">{shift.shiftdescr}</StyledTableCell> */}
                                                    </TableRow>
                                                    {/* <TableRow key={shift.shiftdate.toString() + shift.shiftstarttime.toString() + shift.shiftalertid.toString()}> */}
                                                    {/* <TableCell sx={{ fontSize: 16, color: 'grey' }} align="center">Details:</TableCell> */}
                                                    {/* <TableCell sx={{ fontSize: 16, color: 'grey' }} align="center">Role:</TableCell> */}
                                                    {/* </TableRow> */}
                                                    <StyledTableInnerRow key={shift.cono + shift.shiftalertid.toString() + 'desc'} sx={{ "& td": { border: 0 } }}>
                                                        <StyledTableCell colSpan={2} align="center">{shift.descr} </StyledTableCell>
                                                        {/* <StyledTableCell align="center">{shift.Roles}</StyledTableCell> */}
                                                        {/* <StyledTableCell></StyledTableCell> */}
                                                        {/* <StyledTableCell></StyledTableCell> */}
                                                    </StyledTableInnerRow>
                                                    {hideFac ?
                                                        <></>
                                                        : <StyledTableInnerRow key={shift.cono + shift.shiftalertid.toString() + 'comp'} sx={{ "& td": { border: 0 } }}>
                                                            <StyledTableCell colSpan={2} align="center">{shift.companyname}</StyledTableCell>
                                                            {/* <StyledTableCell align="center">{shift.Roles}</StyledTableCell> */}
                                                            {/* <StyledTableCell></StyledTableCell> */}
                                                            {/* <StyledTableCell></StyledTableCell> */}
                                                        </StyledTableInnerRow>}
                                                    {shift.status === 0 && //0 means still available
                                                        <StyledTableInnerRow key={shift.cono + shift.shiftalertid.toString() + 'claim'} >
                                                            <StyledTableCell align="center">
                                                                <Button sx={{ marginBottom: 1, borderRadius: 5 }} color='warning' variant='contained' onClick={() => handleClickAccept(shift)} className="btn btn-sm btn-danger btn-delete-user">
                                                                    <span>Claim Shift</span>
                                                                </Button>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                <Button sx={{ marginBottom: 1, borderRadius: 5 }} color='error' variant='contained' onClick={() => handleClickDeny(shift)} className="btn btn-sm btn-danger btn-delete-user">
                                                                    <span>Deny Shift</span>
                                                                </Button>
                                                            </StyledTableCell>
                                                        </StyledTableInnerRow>
                                                    }
                                                    {shift.status === 1 && //1 means already claimed but not confirmed
                                                        <TableRow key={shift.cono + shift.shiftalertid.toString() + 'claimed'} >
                                                            <TableCell sx={{ fontSize: 16, backgroundColor: '#ffb74d' }} align="center" colSpan={2}>
                                                                <b>Claimed</b>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {shift.status === 2 && //2 means already denied
                                                        <TableRow key={shift.cono + shift.shiftalertid.toString() + 'denied'} >
                                                            <TableCell sx={{ fontSize: 16, backgroundColor: '#e6e6e6' }} align="center" colSpan={2}>
                                                                <b>Denied This Shift</b>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {shift.status === 3 && //no more openings
                                                        <TableRow key={shift.cono + shift.shiftalertid.toString() + 'nomore'} >
                                                            <TableCell sx={{ fontSize: 16, backgroundColor: '#e6e6e6' }} align="center" colSpan={2}>
                                                                <b>No More Openings Remaining</b>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {shift.status === 4 && //claimed by user and confirmed by fac
                                                        <TableRow key={shift.cono + shift.shiftalertid.toString() + 'confirmed'} >
                                                            <TableCell sx={{ fontSize: 16, backgroundColor: '#aed581' }} align="center" colSpan={2}>
                                                                <b>Claimed and Confirmed</b>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </React.Fragment>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer></>
                                :
                                <></>}
                            <b>All Shifts:</b>
                            <TableContainer component={Paper} sx={{ width: "max-content", alignItems: 'center', marginBottom: 20, marginTop: 1 }}>
                                <Table size='small' aria-label="simple table">
                                    <TableBody>
                                        {getAllOpenShiftsForNewKey.filter(excludeHighlightedShift).map(shift =>
                                            <React.Fragment>
                                                <TableRow sx={{ backgroundColor: '#1976d2' }} key={shift.shiftdate.toString() + shift.shiftstarttime.toString() + shift.shiftalertid.toString()}>
                                                    <TableCell sx={{ fontSize: 16, color: 'white' }} align="center">Shift</TableCell>
                                                    {/* <TableCell sx={{ fontSize: 16, color: 'white' }} align="center">Shift Time:</TableCell> */}
                                                    <TableCell sx={{ fontSize: 16, color: 'white' }} align="center">Role</TableCell>
                                                </TableRow>
                                                <TableRow key={shift.cono + shift.shiftalertid.toString() + 'roles'} sx={{ "& td": { border: 0 } }}>
                                                    <StyledTableCell align="center">{format(new Date(shift.shiftdate), 'MM/dd/yyyy')}<br />{format(new Date(shift.shiftstarttime), "hh:mm a")} - {format(new Date(shift.shiftendtime), "hh:mm a")}</StyledTableCell>
                                                    <StyledTableCell align="center">{shift.Roles}</StyledTableCell>
                                                    {/* <StyledTableCell align="center">{format(new Date(shift.shiftstarttime), "hh:mm a")} - {format(new Date(shift.shiftendtime), "hh:mm a")}</StyledTableCell> */}
                                                    {/* <StyledTableCell align="center">}</StyledTableCell> */}
                                                    {/* <StyledTableCell align="center">{shift.shiftdescr}</StyledTableCell> */}
                                                </TableRow>
                                                {/* <TableRow key={shift.shiftdate.toString() + shift.shiftstarttime.toString() + shift.shiftalertid.toString()}> */}
                                                {/* <TableCell sx={{ fontSize: 16, color: 'grey' }} align="center">Details:</TableCell> */}
                                                {/* <TableCell sx={{ fontSize: 16, color: 'grey' }} align="center">Role:</TableCell> */}
                                                {/* </TableRow> */}
                                                <StyledTableInnerRow key={shift.cono + shift.shiftalertid.toString() + 'desc'} sx={{ "& td": { border: 0 } }}>
                                                    <StyledTableCell colSpan={2} align="center">{shift.descr} </StyledTableCell>
                                                    {/* <StyledTableCell align="center">{shift.Roles}</StyledTableCell> */}
                                                    {/* <StyledTableCell></StyledTableCell> */}
                                                    {/* <StyledTableCell></StyledTableCell> */}
                                                </StyledTableInnerRow>
                                                {hideFac ?
                                                    <></>
                                                    : <StyledTableInnerRow key={shift.cono + shift.shiftalertid.toString() + 'comp'} sx={{ "& td": { border: 0 } }}>
                                                        <StyledTableCell colSpan={2} align="center">{shift.companyname}</StyledTableCell>
                                                        {/* <StyledTableCell align="center">{shift.Roles}</StyledTableCell> */}
                                                        {/* <StyledTableCell></StyledTableCell> */}
                                                        {/* <StyledTableCell></StyledTableCell> */}
                                                    </StyledTableInnerRow>}
                                                {shift.status === 0 && //0 means still available
                                                    <StyledTableInnerRow key={shift.cono + shift.shiftalertid.toString() + 'claim'} >
                                                        <StyledTableCell align="center">
                                                            <Button sx={{ marginBottom: 1, borderRadius: 5 }} color='warning' variant='contained' onClick={() => handleClickAccept(shift)} className="btn btn-sm btn-danger btn-delete-user">
                                                                <span>Claim Shift</span>
                                                            </Button>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            <Button sx={{ marginBottom: 1, borderRadius: 5 }} color='error' variant='contained' onClick={() => handleClickDeny(shift)} className="btn btn-sm btn-danger btn-delete-user">
                                                                <span>Deny Shift</span>
                                                            </Button>
                                                        </StyledTableCell>
                                                    </StyledTableInnerRow>
                                                }
                                                {shift.status === 1 && //1 means already claimed but not confirmed
                                                    <TableRow key={shift.cono + shift.shiftalertid.toString() + 'claimed'} >
                                                        <TableCell sx={{ fontSize: 16, backgroundColor: '#ffb74d' }} align="center" colSpan={2}>
                                                            <b>Claimed</b>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                                {shift.status === 2 && //2 means already denied
                                                    <TableRow key={shift.cono + shift.shiftalertid.toString() + 'denied'} >
                                                        <TableCell sx={{ fontSize: 16, backgroundColor: '#e6e6e6' }} align="center" colSpan={2}>
                                                            <b>Denied This Shift</b>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                                {shift.status === 3 && //no more openings
                                                    <TableRow key={shift.cono + shift.shiftalertid.toString() + 'nomore'} >
                                                        <TableCell sx={{ fontSize: 16, backgroundColor: '#e6e6e6' }} align="center" colSpan={2}>
                                                            <b>No More Openings Remaining</b>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                                {shift.status === 4 && //claimed by user and confirmed by fac
                                                    <TableRow key={shift.cono + shift.shiftalertid.toString() + 'confirmed'} >
                                                        <TableCell sx={{ fontSize: 16, backgroundColor: '#aed581' }} align="center" colSpan={2}>
                                                            <b>Claimed and Confirmed</b>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </React.Fragment>
                                        )}
                                        {!newOpensShiftsByKey &&
                                            <StyledTableRow key={-250}>
                                                <StyledTableCell className="text-center">
                                                    <div className="p-2">No Shifts Or Invalid Key</div>
                                                </StyledTableCell>
                                            </StyledTableRow >
                                        }
                                        {newOpensShiftsByKey && newOpensShiftsByKey.size == 0 &&
                                            <StyledTableRow key={-350}>
                                                <StyledTableCell className="text-center">
                                                    <div className="p-2">No Shifts Or Invalid Key</div>
                                                </StyledTableCell>
                                            </StyledTableRow >
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>}
                </div>}
        </>
    )
})