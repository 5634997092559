export interface User{
    username: string;
    firstname: string;
    lastname: string;
    credentials: string;
    email: string;
    Roles:string;
    phone: string;
    optinemail: boolean;
    optintext: boolean;
    active: boolean;
    accountverifiedbycompany: boolean;
    onhold: boolean;
    hiredate: Date;
    AutoAssigned:boolean;
    isSelected:boolean;
    employeeid:string;
    isagency:boolean;
}
export interface UserFormValues{
    email: string;
    password: string;
    displayName?: string;
    username?: string;
}

export class UserFormValuesModel{
    public email: string = "";
    public password: string = "";
    public error: string = "";
    
    constructor(email: string, pass: string){
        this.email = email;
        this.password = pass;
    }
}