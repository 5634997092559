import React, { useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, LinearProgress, List, ListItem, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useStore } from '../../stores/store';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { NewShiftInRotation } from '../../models/Schedule/NewShiftInRotation';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Yup from "yup";

interface SelectRoleDialogProps {
    open: boolean;
    name: string;
    username: string;
    shiftdate: Date;
    scheduleid: number;
    roleid: number;
    role: string;
    onClose: (value: boolean) => void;
}
interface Rotation {
    dow: string;
    shiftid: number;
    starttime: Date;
    endTime: Date;
    shiftendsnextday: boolean;
    partialshift: boolean;
    descr: string;
}

interface FormValues {
    shiftDate: string;
    selectedrole: string;
    selectedshift: string;
    startTime: Date;
    endTime: Date;
}
const ValidSchema = Yup.object().shape({
    selectedshift: Yup.string().required("Shift Is Required"),
});

export default observer(function EditShiftFromScheduleDialog(props: SelectRoleDialogProps) {
    const { shiftStore, scheduleStore } = useStore();
    const { loadShiftSetupsForRole, getShiftSetupsForRole } = shiftStore;
    const { loading, getUnitSetups, removeShiftFromSchedule, putEditUserShiftInSchedule, loadingDetails,
        loadShiftInfoByKey, scheduleshiftinfo } = scheduleStore;
    const { onClose, open } = props;
    const [selectedUnitID, setSelectedUnitID] = React.useState(-1);
    const [selectedShiftID, setSelectedShiftID] = React.useState(-1);

    const [originalStartTime, setOriginalStartTime] = React.useState(new Date()); //used to see if we are doing a partial shift
    const [originalEndTime, setOriginalEndTime] = React.useState(new Date());
    const [error, setError] = React.useState('');
    const [loadingEdit, setLoadingEdit] = React.useState(true);
    const [areYouSureDeleteShiftOpen, setAreYouSureDeleteShiftOpen] = React.useState(false);
    const [shiftSelectedError, setShiftSelectedError] = React.useState(false);

    useEffect(() => {
        if(props.open){
            setLoadingEdit(true);

            loadShiftSetupsForRole(props.roleid).then(() => {
                loadShiftInfoByKey(props.username, props.scheduleid).then((temp) => {
                    //  alert(JSON.stringify(temp, null, 2));
                    if (temp != undefined) {
                        // alert(JSON.stringify(scheduleshiftinfo, null, 2));
                        setSelectedUnitID(temp.unitid);
                        setSelectedShiftID(temp.shiftid);
                        formik.values.startTime = temp.shiftstarttime;
                        formik.setFieldValue("startTime", temp.shiftstarttime);
                        formik.values.endTime = temp.shiftendtime;
                        formik.setFieldValue("endTime", temp.shiftendtime);
                    }
                    setLoadingEdit(false);
                });
            });


            setShiftSelectedError(false);
        }

    }, [props.username, props.shiftdate, props.scheduleid]);

    const handleClickDeleteShiftNo = () => {
        setAreYouSureDeleteShiftOpen(false);
    }

    const handleClickDeleteShiftYes = () => {
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        let shift = {
            cono: cono, username: props.username, scheduleid: props.scheduleid
        };

        if (shift.scheduleid > -1) {
            removeShiftFromSchedule(shift).then(() => {
                setAreYouSureDeleteShiftOpen(false);
                setSelectedShiftID(-1);
                setSelectedUnitID(-1);
                formik.resetForm();
                onClose(true);
            })
        }
    }

    const handleClickRemoveShift = () => {
        setAreYouSureDeleteShiftOpen(true);
    }

    const handleUnitChange = (event: SelectChangeEvent<number>) => {
        setSelectedUnitID(Number.parseInt(event.target.value.toString()));

        formik.handleChange(event.target.value.toString());
    }

    const handleShiftChange = (event: SelectChangeEvent<number>) => {
        setSelectedShiftID(Number.parseInt(event.target.value.toString()));
        formik.handleChange(event.target.value.toString());
        // if (shiftid > -1) {
        //     getShiftSetupsForRole.forEach((s) => {
        //         if (s.shiftid == shiftid) {
        //             formik.values.startTime = new Date(s.shiftstarttime);
        //             formik.values.endTime = new Date(s.shiftendtime);
        //             setOriginalStartTime(s.shiftstarttime);
        //             setOriginalEndTime(s.shiftendtime);
        //         }
        //     })
        //     formik.handleChange(event.target.value.toString());

        //     setShiftSelectedError(false);
        // }
    }

    const handleClose = () => {
        // setSelectedShiftObject('');
        setSelectedShiftID(-1);
        setSelectedUnitID(-1);
        scheduleStore.clearShiftInfo();
        formik.resetForm();
        onClose(false);
    };

    function areDatesEqual(date1: Date, date2: Date): boolean {
        // Convert both dates to the same timezone (e.g., UTC)
        const utcDate1 = Date.UTC(
            date1.getFullYear(),
            date1.getMonth(),
            date1.getDate(),
            date1.getHours(),
            date1.getMinutes(),
            date1.getSeconds()
        );
        const utcDate2 = Date.UTC(
            date2.getFullYear(),
            date2.getMonth(),
            date2.getDate(),
            date2.getHours(),
            date2.getMinutes(),
            date2.getSeconds()
        );

        // Compare the UTC timestamps
        return utcDate1 === utcDate2;
    }

    function doesShiftEndNextDay(date1: Date, date2: Date): boolean {
        // Convert both dates to the same timezone (e.g., UTC)
        if (new Date(date2).getHours() < new Date(date1).getHours()) {
            return true;
        }
        else {
            return false;
        }
    }

    const formik = useFormik({
        initialValues: {
            shiftDate: new Date().toISOString().split('T')[0],
            selectedroleobject: '',
            selectedshiftobject: '',
            selectedunitobject: '',
            startTime: new Date(),
            endTime: new Date(),
        },
        // validationSchema: ValidSchema,
        // validateOnBlur: true,
        onSubmit: (values) => {
            setError('');
            let co = window.localStorage.getItem('cono');
            if (!co) {
                return;
            }
            if (selectedShiftID < 0) {
                setShiftSelectedError(true);
                return;
            }
            let usernames = [];
            usernames.push(props.username);
            const shiftdatewithouttime = new Date(props.shiftdate.getFullYear(), props.shiftdate.getMonth(), props.shiftdate.getDate());
            const endshiftdate = new Date(props.shiftdate.getFullYear(), props.shiftdate.getMonth(), props.shiftdate.getDate());
            // if (shift.shiftendsnextday) {
            //     endshiftdate.setDate(endshiftdate.getDate() + 1);
            // }
            let shiftendsnextday = false;
            if(doesShiftEndNextDay(values.startTime,values.endTime)){
                shiftendsnextday = true;
            }
            let user = {
                cono: co, roleid: props.roleid, shiftdate: shiftdatewithouttime,scheduleid: props.scheduleid,username: props.username,
                shiftstarttime: new Date(new Date(shiftdatewithouttime).setHours(new Date(values.startTime).getHours(), new Date(values.startTime).getMinutes(), 0, 0)).toLocaleString(),
                shiftendtime: new Date(new Date(endshiftdate).setHours(new Date(values.endTime).getHours(), new Date(values.endTime).getMinutes(), 0, 0)).toLocaleString(),
                shiftid: selectedShiftID, shiftendsnextday: shiftendsnextday, partialshift: false,
                usernames: usernames, unitid: selectedUnitID
            }
            scheduleStore.putEditUserShiftInSchedule(user).then(() => {
                formik.resetForm();
                onClose(true);
            })
        }
    });

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='md'>
                <DialogTitle>Edit Shift In Schedule</DialogTitle>
                {loadingEdit ?
                    <>
                        <DialogContent dividers={true}>
                            <LinearProgress />
                        </DialogContent>
                    </>
                    :
                    <DialogContent dividers={true}>
                        <form onSubmit={formik.handleSubmit} >
                            <Stack alignContent={'center'} alignItems={'center'}>
                                <Typography sx={{ margin: 1 }}>User: {props.name}({props.username})</Typography>
                                <Typography sx={{ margin: 1 }}>Date: {new Date(props.shiftdate).toDateString()}</Typography>
                                <Typography sx={{ margin: 1 }}>Role: {props.role}</Typography>
                                <FormControl sx={{ marginTop: 1, marginRight: 1 }}>
                                    <InputLabel id="Unit-labelid">Unit</InputLabel>
                                    <Select
                                        labelId="Unit-labelid"
                                        label="Unit"
                                        sx={{ width: 260, fontSize: 13, height: 50, margin: 1 }}
                                        value={selectedUnitID}
                                        onChange={handleUnitChange}>
                                        {getUnitSetups.map((unit) => (
                                            <MenuItem key={unit.unitid} value={unit.unitid}>
                                                {unit.unitname}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ marginTop: 1, marginRight: 1 }}>
                                    <InputLabel id="shift-labelid">Shift</InputLabel>
                                    <Select
                                        labelId="shift-labelid"
                                        label="Shift"
                                        sx={{ width: 260, fontSize: 13, height: 50, margin: 1 }}
                                        value={selectedShiftID}
                                        error={shiftSelectedError}
                                        onChange={handleShiftChange}>
                                        {getShiftSetupsForRole.map((shift) => (
                                            <MenuItem key={shift.shiftid} value={shift.shiftid}>
                                                {shift.descr}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {formik.errors.selectedshiftobject &&
                                    <Typography style={{ marginBottom: 10 }} color='red'>Shift Is Required</Typography>
                                }
                                {selectedShiftID >= 0 ?
                                    <>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                name="startTime"
                                                label="Start Time"
                                                minutesStep={15}
                                                value={dayjs(formik.values.startTime)}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue("startTime", dayjs(newValue));
                                                }}
                                                formatDensity='dense'
                                                slotProps={{ textField: { size: 'small' } }}
                                                sx={{ marginTop: 1, width: 135, height: 50 }}
                                            />
                                        </LocalizationProvider>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                name="endTime"
                                                label="End Time"
                                                minutesStep={15}
                                                value={dayjs(formik.values.endTime)}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue("endTime", dayjs(newValue));
                                                }}
                                                formatDensity='dense'
                                                slotProps={{ textField: { size: 'small' } }}
                                                sx={{ margin: 1, width: 135, height: 50 }}
                                            />
                                        </LocalizationProvider>
                                    </>
                                    : <></>}
                            </Stack>
                        </form>
                    </DialogContent>
                }
                <DialogActions>
                    <Box display="flex" justifyContent="end" width="100%">
                        <LoadingButton sx={{ width: 35, height: 35, marginRight: 1 }} loading={loadingDetails} variant='contained' color='error' onClick={handleClickRemoveShift}>
                            <DeleteIcon sx={{ fontSize: 16 }} />
                        </LoadingButton>
                        <Box display="flex" justifyContent="end" sx={{ alignContent: 'end', alignItems: 'end', alignSelf: 'end' }}>
                            <Button variant='outlined' onClick={handleClose}>Cancel</Button>
                            <LoadingButton sx={{ marginLeft: 1 }} loading={loadingDetails} variant='contained' onClick={formik.submitForm}>Save Shift</LoadingButton>
                        </Box>
                    </Box>
                    
                </DialogActions>
            </Dialog>
            <Dialog
                open={areYouSureDeleteShiftOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are You Sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to remove this shift?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClickDeleteShiftNo}>No</Button>
                    <LoadingButton loading={loading} variant='contained' onClick={handleClickDeleteShiftYes} autoFocus>
                        Yes
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
})