import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Button, IconButton } from "@mui/material";
import Box from '@mui/material/Box';
import { format } from 'date-fns'
import LinearProgress from '@mui/material/LinearProgress';
import { Stack } from '@mui/system';
import { useParams } from 'react-router-dom'
import { OpenShiftResponse } from '../../models/OpenShiftReponse';
import { OpenShift } from '../../models/OpenShift';
import ViewListIcon from '@mui/icons-material/ViewList';
import MainCalendar from '../calendar/MainCalendar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CurrentShiftsUserCalendar from '../calendar/CurrentShiftsUserCalendar';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
    },
}));

const StyledTableInnerRow = styled(TableRow)(({ theme }) => ({
    root: {
        border: 1
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
}));

export default observer(function ShiftListForRole() {
    const { shiftStore } = useStore();
    const { shiftsByRole, loadShiftForRoleByKey, getAllShiftsForRole, loading, shiftsByRoleInfo } = shiftStore;
    const { key } = useParams<{ key: string }>();
    let fac = '1';
    const [username] = React.useState('');
    const [shiftWasClaimed, setShiftWasClaimed] = React.useState(false);
    const [shiftWasDenied, setShiftWasDenied] = React.useState(false);
    const [shiftIsUnavailable, setShiftIsUnavailable] = React.useState(false);
    const [showListView, setShowListView] = React.useState(true);

    const clickShowListView = () => {
        setShowListView(true);
    }

    const clickShowCalendarView = () => {
        setShowListView(false);
    }

    const handleClickAccept = (shift: OpenShift) => {
        if (fac && shiftsByRoleInfo) {
            console.log("try to claim shift id " + shift.shiftalertid + shiftsByRoleInfo.username);
            shiftStore.putOpenShiftResponse(new OpenShiftResponse(fac, shift.shiftalertid, shiftsByRoleInfo.username, true)).then((result) => {
                if (result === 'Shift Unavailable') {
                    setShiftIsUnavailable(true);
                }
                else {
                    setShiftWasClaimed(true);
                }
                if (key) {
                    loadShiftForRoleByKey(fac, key);
                }
            });
        }
    }

    const handleClickDeny = (shift: OpenShift) => {
        if (fac && shiftsByRoleInfo) {
            console.log("try to deny shift id " + shift.shiftalertid + shiftsByRoleInfo.username);
            shiftStore.putOpenShiftResponse(new OpenShiftResponse(fac, shift.shiftalertid, shiftsByRoleInfo.username, false)).then((result) => {
                if (result === 'Shift Unavailable') {
                    setShiftIsUnavailable(true);
                }
                else {
                    setShiftWasDenied(true);
                }
                if (key) {
                    loadShiftForRoleByKey(fac, key);
                }
            });
        }
    }

    useEffect(() => {
        document.title = 'Current Shifts';        
        if (fac && key) {
            console.log('we got it ' + key);
            loadShiftForRoleByKey(fac, key);
        }
    }, []);

    if (loading) return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{
                    textAlign: 'center', fontSize: '16',
                    fontWeight: 700,
                    color: 'inherit', marginTop: 2, marginBottom: 0,
                }}>
                    Open Shifts
                </h1>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            </Stack>
        </div>
    )

    return (
        <div style={{ width:'100%', justifyContent: 'center' }}>
            <Stack style={{ marginBottom: 25, alignItems: 'center', justifyContent: 'center' }}>
                <Stack direction='row' marginLeft={11}>
                    <h1 style={{
                        textAlign: 'center', fontSize: '16',
                        fontWeight: 700,
                        color: 'inherit', marginTop: 2, marginBottom: 0,
                    }}>
                        Open Shifts
                    </h1>
                    <IconButton
                        sx={{ marginLeft: 3 }}
                        aria-label="expand row"
                        size="small"
                        onClick={clickShowListView}>
                        {showListView ? <ViewListIcon color='primary' /> : <ViewListIcon />}
                    </IconButton>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={clickShowCalendarView}>
                        {showListView ? <CalendarTodayIcon /> : <CalendarTodayIcon color='primary' />}
                    </IconButton>
                </Stack>
                {shiftsByRoleInfo &&
                    <>
                        {shiftsByRoleInfo.companyname}
                        <Stack direction='row' alignItems={'center'}>
                            <Stack alignItems={'center'} textAlign={'center'} marginLeft={2} marginRight={2}>
                                <b>User:</b>
                                {shiftsByRoleInfo.name}
                            </Stack>
                            <Stack alignItems={'center'} textAlign={'center'} marginLeft={2} marginRight={2}>
                                <b>Roles:</b>
                                {shiftsByRoleInfo.roles}
                            </Stack>
                        </Stack>
                    </>
                }
                {showListView ?
                    <>
                        <TableContainer component={Paper} sx={{ width: "max-content", alignItems: 'center', marginBottom: 20, marginTop: 1 }}>
                            <Table size='small' aria-label="simple table">
                                <TableBody>
                                    {getAllShiftsForRole.map(shift =>
                                        <React.Fragment>
                                            <TableRow sx={{ backgroundColor: '#1976d2' }} key={shift.shiftdate.toString() + shift.shiftstarttime.toString() + shift.shiftalertid.toString()}>
                                                <TableCell sx={{ fontSize: 16, color: 'white' }} align="center">Shift</TableCell>
                                                {/* <TableCell sx={{ fontSize: 16, color: 'white' }} align="center">Shift Time:</TableCell> */}
                                                <TableCell sx={{ fontSize: 16, color: 'white' }} align="center">Role</TableCell>
                                            </TableRow>
                                            <TableRow key={shift.shiftalertid.toString()} sx={{ "& td": { border: 0 } }}>
                                                <StyledTableCell align="center">{format(new Date(shift.shiftdate), 'MM/dd/yyyy')}<br />{format(new Date(shift.shiftstarttime), "hh:mm a")} - {format(new Date(shift.shiftendtime), "hh:mm a")}</StyledTableCell>
                                                <StyledTableCell align="center">{shift.Roles}</StyledTableCell>
                                                {/* <StyledTableCell align="center">{format(new Date(shift.shiftstarttime), "hh:mm a")} - {format(new Date(shift.shiftendtime), "hh:mm a")}</StyledTableCell> */}
                                                {/* <StyledTableCell align="center">}</StyledTableCell> */}
                                                {/* <StyledTableCell align="center">{shift.shiftdescr}</StyledTableCell> */}
                                            </TableRow>
                                            {/* <TableRow key={shift.shiftdate.toString() + shift.shiftstarttime.toString() + shift.shiftalertid.toString()}> */}
                                            {/* <TableCell sx={{ fontSize: 16, color: 'grey' }} align="center">Details:</TableCell> */}
                                            {/* <TableCell sx={{ fontSize: 16, color: 'grey' }} align="center">Role:</TableCell> */}
                                            {/* </TableRow> */}
                                            <StyledTableInnerRow sx={{ "& td": { border: 0 } }}>
                                                <StyledTableCell colSpan={2} align="center">{shift.descr}</StyledTableCell>
                                                {/* <StyledTableCell align="center">{shift.Roles}</StyledTableCell> */}
                                                {/* <StyledTableCell></StyledTableCell> */}
                                                {/* <StyledTableCell></StyledTableCell> */}
                                            </StyledTableInnerRow>
                                            {shift.status === 0 && //0 means still available
                                                <StyledTableInnerRow>
                                                    <StyledTableCell align="center">
                                                        <Button sx={{ marginBottom: 1, borderRadius:5 }} color='warning' variant='contained' onClick={() => handleClickAccept(shift)} className="btn btn-sm btn-danger btn-delete-user">
                                                            <span>Claim Shift</span>
                                                        </Button>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <Button sx={{ marginBottom: 1, borderRadius:5 }} color='error' variant='contained' onClick={() => handleClickDeny(shift)} className="btn btn-sm btn-danger btn-delete-user">
                                                            <span>Deny Shift</span>
                                                        </Button>
                                                    </StyledTableCell>
                                                </StyledTableInnerRow>
                                            }
                                            {shift.status === 1 && //1 means already claimed but not confirmed
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: 16, backgroundColor: '#ffb74d' }} align="center" colSpan={2}>
                                                        <b>Claimed</b>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                            {shift.status === 2 && //2 means already denied
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: 16, backgroundColor: '#e6e6e6' }} align="center" colSpan={2}>
                                                        <b>Denied This Shift</b>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                            {shift.status === 3 && //no more openings
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: 16, backgroundColor: '#e6e6e6' }} align="center" colSpan={2}>
                                                        <b>No More Openings Remaining</b>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                            {shift.status === 4 && //claimed by user and confirmed by fac
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: 16, backgroundColor: '#aed581' }} align="center" colSpan={2}>
                                                        <b>Claimed and Confirmed</b>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </React.Fragment>
                                    )}
                                    {!getAllShiftsForRole &&
                                        <StyledTableRow key={-2}>
                                            <StyledTableCell className="text-center">
                                                <div className="p-2">No Shifts Or Invalid Key</div>
                                            </StyledTableCell>
                                        </StyledTableRow >
                                    }
                                    {getAllShiftsForRole && !getAllShiftsForRole.length &&
                                        <StyledTableRow key={-3}>
                                            <StyledTableCell className="text-center">
                                                <div className="p-2">No Shifts Or Invalid Key</div>
                                            </StyledTableCell>
                                        </StyledTableRow >
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                    :
                    <CurrentShiftsUserCalendar />}
            </Stack>
        </div>
    );
})