import React, { useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useStore } from '../../stores/store';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { TrendingUp } from '@mui/icons-material';
import { User } from '../../models/User';
import { NewUserInRotation } from '../../models/Schedule/NewUserInRotation';
import { LoadingButton } from '@mui/lab';

interface DialogProps {
    open: boolean;
    rotationid: number;
    savetodb: boolean;
    onClose: (value: boolean) => void;
}
interface Rotation {
    dow: string;
    shiftid: number;
    starttime: Date;
    endTime: Date;
    shiftendsnextday: boolean;
    partialshift: boolean;
    descr: string;
}

interface FormValues {
    selectedrole: string;
    assignedUsers: string[];
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

export default observer(function AddUserToRortation(props: DialogProps) {
    const { userStore, shiftStore, scheduleStore } = useStore();
    const { getCompanyRoles, loadUsers, getAllUsers } = userStore;
    const { putNewUserInRotation, loading } = scheduleStore;
    const { onClose, open } = props;
    const [selectedroleobject, setSelectedRoleObject] = React.useState('');

    useEffect(() => {
        loadUsers();
    }, []);

    const formik = useFormik<FormValues>({
        initialValues:{
            selectedrole: '',
            assignedUsers: []
        },
        onSubmit: (values) => {
            console.log(values);
            handleSaveAndClose();
        },
    });

    function excludeRoles(user: User) {        
        let result: boolean;
        result = false;
        let role = JSON.parse(selectedroleobject);
        const values = user.Roles.split(',');
        values.forEach((v: string) =>{
            if(v.trim() === role.role.trim()){
                result =  true;
            }
        })
        return result;
    }
    const handleRoleChange = (event: SelectChangeEvent<string>) => {
        console.log(event.target.value.toString());
        setSelectedRoleObject(event.target.value.toString());
        let role = JSON.parse(event.target.value.toString());
        if (role != null && role.roleid > -1) {
            formik.values.assignedUsers = [];
        }
    };

    const handleClose = () => {
        formik.resetForm();
        onClose(false);
    };

    const handleSaveAndClose = () =>{
        if(selectedroleobject == ''){
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        let role = JSON.parse(selectedroleobject);
        let rot = {cono: cono, roleid: role.roleid, usernames: formik.values.assignedUsers, rotationid: props.rotationid};
        console.log('try to save '+ rot);
        if(props.savetodb){
        putNewUserInRotation(rot).then(() =>{
            formik.resetForm();
            onClose(true);
        })
    }
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='md'>
                <DialogTitle>Add User(s) To Rotation</DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                <DialogContent dividers={true}>
                    <Stack alignContent={'center'} alignItems={'center'}>
                        <FormControl sx={{ margin: 1 }}>
                            <InputLabel id="role-label">Role</InputLabel>
                            <Select
                                labelId='role-label'
                                label="Role"
                                value={selectedroleobject}
                                onChange={handleRoleChange}
                                sx={{ display: 'flex', width: 360 }} >
                                {getCompanyRoles.map((role) => (
                                    <MenuItem key={role.roleid} value={JSON.stringify({ roleid: role.roleid, role: role.role })}>
                                        {role.role}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m:1, mt:3}}>
                            <InputLabel id="role-label">User</InputLabel>
                            <Select sx={{ display: 'flex', width: 360,fontSize:13,'& #my-simple-select': {
                          fontSize: '10pt',
                       } }}
                                labelId="assigned-users-label"
                                id="assignedUsers"
                                name="assignedUsers"
                                label='User'
                                multiple
                                value={formik.values.assignedUsers}
                                input={<OutlinedInput sx={{fontSize: '10'}} label="Tag" />}
                                MenuProps={MenuProps}
                                onChange={formik.handleChange}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {selectedroleobject != '' ? getAllUsers.filter(excludeRoles).map((user) => (
                                    <MenuItem key={user.username} value={user.username}>
                                        <Checkbox sx={{height:15,width:15, marginRight:1, paddingTop:1}} checked={formik.values.assignedUsers.indexOf(user.username) > -1} />
                                        <ListItemText primary={<Typography variant="h5" style={{ fontSize:11,marginTop:1 }}>{user.isagency ? '(a) ' : <></>}{user.lastname}, {user.firstname} - {user.username}</Typography>}/>
                                    </MenuItem>
                                )) : <></>}
                            </Select>
                        </FormControl>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClose}>Cancel</Button>
                    <LoadingButton variant='contained' loading={loading} type="submit">Save</LoadingButton>
                </DialogActions>
                </form>
            </Dialog>
        </>
    );
})