import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import TextField from '@mui/material/TextField';
import { Button, Checkbox, Container, Box, Stack, Typography, Alert, Snackbar, FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import { useStore } from '../../stores/store';
import NewUserModel, { NewUserModelSchema } from '../../models/NewUser';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Select } from '@mui/material';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import { useParams } from 'react-router-dom'
import RegisterNewUserModel, { RegisterNewUserModelSchema } from '../../models/RegisterNewUserModel';
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as Yup from "yup";
import CompanyRoleModel from '../../models/CompanyRole';
import { useNavigate } from "react-router-dom";
import { ConstructionOutlined } from '@mui/icons-material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default observer(function SelfEnrollUser() {
    const { userStore } = useStore();
    const { loading, loadCompanyRolesByKey, checkUniqueUsername, IsUsernameAvailable, getCompanyRolesByKey, registerNewUser, getCompanyInfoByKey, companyInfo, IsPhoneNumberAvailable, checkUniquePhone } = userStore;
    let [checkUserStatus, setCheckUserStatus] = React.useState(0); //0 mean never checked, 1 means check and not unique, 2 means checks and we are unique
    let [checkPhoneNumberStatus, setCheckPhoneNumberStatus] = React.useState(0); //0 mean never checked, 1 means check and not unique and hasnt accept terms, 2 means not unique and has accepted terms, 3 means we are unique/new phone
    const [phoneerror, setPhoneError] = React.useState('');
    const { key } = useParams<{ key: string }>();
    const [fac, setfac] = React.useState('');
    const [success, setSuccess] = React.useState(false);
    const [initialized, setInitialized] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [selectedRoleError, setSelectedRoleError] = React.useState(false);

    const handleCloseCancel = () => {
        setOpen(false);
    };

    const handleClickRegister = () => {
        formik.validateForm();
        if (formik.values.roleid <= 0) {
            setSelectedRoleError(true);
            return;
        } else {
            setSelectedRoleError(false);
        }
        console.log('form is ' + formik.isValid);
        if (formik.isValid && checkUserStatus != 1 && (checkPhoneNumberStatus == 1 || checkPhoneNumberStatus == 3)) {
            setOpen(true);
        }
    }

    const handleCloseSnack = () => {
        setOpenSnack(false);
        let path = `/entercompanycode`;
        navigate(path);
    }

    function excludeAdministrationRole(role: CompanyRoleModel) {
        return !role.isadministrationrole;
    }

    let navigate = useNavigate();
    useEffect(() => {
        document.title = 'Register For Fill Open Shifts';
        ///gotta get fac id by KEY
        // const f = getCompanyRolesByKey(key);
        // setfac(f.cono);
        if (!key) {
            return;
        }
        getCompanyInfoByKey(key).then(() => {
            loadCompanyRolesByKey(key).then(() => {
                setInitialized(true);
            })
        })
    }, []);

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email("Invalid email address format"),
        firstname: Yup.string()
            .required("First name is required"),
        lastname: Yup.string()
            .required("Last name is required"),
        phone: Yup.string()
            .required("Phone number is required").min(10).max(12),
        roleid: Yup.number()
            .required("Role is Required").min(1),
    });

    const formik = useFormik({
        initialValues: new RegisterNewUserModel(),
        onSubmit: values => {
            if (!key) {
                return;
            }
            values.key = key;
            //alert(JSON.stringify(values, null, 2));
            if (checkPhoneNumberStatus == 1) {
                console.log('just accepting terms');
                userStore.putUserAcceptedTermsFromRegistration(values).then(() => {
                    console.log('done trying to register new user');
                    handleReset();
                    setSuccess(true);
                })
            } else {
                userStore.registerNewUser(values).then(() => {
                    console.log('done trying to register new user');
                    handleReset();
                    setSuccess(true);
                })
            }
        },
        validationSchema: LoginSchema,
        validateOnBlur: true,

    });

    const handleCloseConfirm = () => {
        setOpen(false);
        formik.submitForm();
    };

    const handleInput = async () => {
        if (formik.values.email) {
            await IsUsernameAvailable(formik.values.email).then((response) => {
                console.log('ok ' + response);
                if (response === true) {
                    setCheckUserStatus(2);
                } else {
                    console.log('set 1');
                    setCheckUserStatus(1);
                }
            });
        }
        if (formik.values.phone && formik.values.phone.length > 9) {
            await IsPhoneNumberAvailable(formik.values.phone.trim()).then((response) => {
                console.log('ok ' + response);
                if (response == 0) { //phone doesnt exist
                    setCheckPhoneNumberStatus(3);
                } else if (response == 1) { //phone exists but user hasnt accepted terms
                    console.log('set 1');
                    setCheckPhoneNumberStatus(1);
                } else { //phone exists and user accepted terms
                    setCheckPhoneNumberStatus(2);
                }
            });
        }
    };

    const handleReset = () => {
        console.log('try to reset');
        formik.resetForm({
            values: { key: "", firstname: '', lastname: '', email: '', optinemail: false, optintext: false, phone: '', roleid: 0, employeeid: '' },
        });
    };

    function excludeAdminRole(value: CompanyRoleModel) {
        return value.role != "Admin";
    }

    function IsUsernameUnique() {
        if (checkUserStatus == 0) {
            return (
                <></>
            )
        }
        if (checkUserStatus == 1) {
            return (
                <ClearRoundedIcon color="error" />
            )
        }
        if (checkUserStatus == 2) {
            return (
                <DoneRoundedIcon color="success" />
            )
        }
        return (<></>);

    };

    function IsPhoneNumberUsed() {
        if (checkPhoneNumberStatus == 0) {
            setPhoneError('');
            return (
                <></>
            )
        }
        if (checkPhoneNumberStatus == 1) {
            setPhoneError(''); //just accepting terms, not actually creating a new user
            return (
                <DoneRoundedIcon color="success" />
            )
        }
        if (checkPhoneNumberStatus == 2) {
            setPhoneError('Phone number already exists in the system');
            return (
                <ClearRoundedIcon color="error" />
            )
        }
        if (checkPhoneNumberStatus == 3) {
            setPhoneError('');
            return (
                <DoneRoundedIcon color="success" />
            )
        }
        return (<></>);

    };

    if (!initialized) {
        return (
            <Container component="main" maxWidth='sm'>
                <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                    <LinearProgress />
                </Card>
            </Container>
        )
    }

    if (!companyInfo || !getCompanyRolesByKey || getCompanyRolesByKey.length == 0) {
        return (
            <Container component="main" maxWidth='sm'>
                <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                    <h1 style={{
                        textAlign: 'center', fontSize: '18',
                        fontWeight: 700,
                        letterSpacing: '.2rem',
                        color: 'inherit'
                    }}>Invalid Company Code</h1>
                </Card>
            </Container>
        )
    }

    if (success) {
        return (
            <Container component="main" maxWidth='sm'>
                <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                    <h1 style={{
                        textAlign: 'center', fontSize: '18',
                        fontWeight: 700,
                        letterSpacing: '.2rem',
                        color: 'inherit'
                    }}>Successfully Registered</h1>
                </Card>
            </Container>
        )
    }

    return (
        <>
            <Container component="main" maxWidth="sm" sx={{ paddingBottom: 10 }}>
                <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                    <Box
                        sx={{
                            marginTop: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Stack direction="column" justifyContent="left" alignItems="center">
                            <Typography component="h1" variant="h5" textAlign={'center'}>
                                {<span>Register For Open Shift Alerts</span>}
                            </Typography>
                            {companyInfo &&
                                <Typography>
                                    {companyInfo.companyname}
                                </Typography>
                            }
                        </Stack>

                        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                            <Stack>
                                <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 1, textAlign: 'left' }}>First Name</InputLabel>
                                <TextField
                                    error={!!formik.errors.firstname}
                                    size='small'
                                    type="text"
                                    name="firstname"
                                    value={formik.values.firstname}
                                    onChange={formik.handleChange}
                                    onBlur={handleInput} />
                                {formik.errors.firstname &&
                                    <Typography style={{ marginBottom: 5, fontSize: 11 }} color='red'>First Name Is Required</Typography>
                                }
                                <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 1, textAlign: 'left' }}>Last Name</InputLabel>
                                <TextField
                                    error={!!formik.errors.lastname}
                                    name="lastname"
                                    size='small'
                                    type="text"
                                    value={formik.values.lastname}
                                    onChange={formik.handleChange}
                                    onBlur={handleInput} />
                                {formik.errors.lastname &&
                                    <Typography style={{ marginBottom: 5, fontSize: 11 }} color='red'>Last Name Is Required</Typography>
                                }
                                <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 1, textAlign: 'left' }}>Employee ID</InputLabel>
                                <TextField
                                    error={!!formik.errors.employeeid}
                                    name="employeeid"
                                    size='small'
                                    type="text"
                                    value={formik.values.employeeid}
                                    onChange={formik.handleChange} />
                                <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 1, textAlign: 'left' }}>Email Address</InputLabel>
                                <TextField
                                    error={!!formik.errors.email}
                                    name="email"
                                    size='small'
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={handleInput} /> {checkUniqueUsername ? <><CircularProgress /></> : <><IsUsernameUnique /></>}
                                <FormGroup>
                                    <FormControlLabel onChange={formik.handleChange} name="optinemail" control={<Checkbox checked={formik.values.optinemail} />} label="Allow Email Alerts" />
                                </FormGroup>
                                <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 1, textAlign: 'left' }}>Phone Number</InputLabel>
                                <TextField
                                    error={!!formik.errors.phone}
                                    name="phone"
                                    size='small'
                                    type="text"
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    onBlur={handleInput} />{checkUniquePhone ? <><CircularProgress /></> : <><IsPhoneNumberUsed /></>}
                                {formik.errors.phone &&
                                    <Typography style={{ marginBottom: 5, fontSize: 11 }} color='red'>Phone Number Is Required</Typography>
                                }
                                <Typography style={{ marginTop: 1, marginBottom: 10, fontSize:11 }} color='red'>{phoneerror}</Typography>
                                <FormGroup>
                                    <FormControlLabel onChange={formik.handleChange} name="optintext" control={<Checkbox checked={formik.values.optintext} />} label="Allow Phone Alerts" />
                                </FormGroup>
                                {/* <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1 }}> */}
                                <InputLabel id="demo-simple-select-autowidth-label" sx={{ textAlign: 'left' }}>Role</InputLabel>
                                <Select
                                    size='small'
                                    name="roleid"
                                    value={formik.values.roleid}
                                    onChange={formik.handleChange}
                                    displayEmpty
                                    required
                                    style={{ display: "block" }}
                                    error={selectedRoleError}>
                                    {getCompanyRolesByKey.filter(excludeAdministrationRole).map((role) => (
                                        <MenuItem key={role.roleid} value={role.roleid}>{role.role}</MenuItem>
                                    ))}
                                </Select>
                                {formik.errors.roleid &&
                                    <Typography style={{ marginBottom: 5, fontSize: 11 }} color='red'>Role Is Required</Typography>
                                }
                                {/* </FormControl> */}
                                <LoadingButton
                                    sx={{ marginTop: 2, marginBottom: 2, borderRadius: 5 }}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={handleClickRegister}
                                    loading={loading}>
                                    Register
                                </LoadingButton>
                            </Stack>
                        </form>
                    </Box>
                </Card>
            </Container>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Registration"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Telephone Consumer Protection Act requires prior express written consent before delivering a pre-recorded message or using automatic dialing equipment devices. These pre-recorded messages are calls or texts left via voicemail or texts.
                        By enrolling in this service and providing your cell phone number, you are consenting to receiving such calls to the cell phone numbers listed below.  This service uses a web link to inform users of available/open shifts.
                        By enrolling in this service and providing your cell number, you are consenting to the use of your mobile data plan to use this system.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCancel}>Disagree</Button>
                    <Button onClick={handleCloseConfirm} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
})