import React, { useEffect } from 'react';
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { Button, Container, Grid, Tooltip, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import { Stack, Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import { blue } from '@mui/material/colors';
import { history } from '../../..';
import { DataGrid, GridRowsProp, GridColDef, GridRowParams, GridRenderCellParams } from '@mui/x-data-grid';
import { VerifyUser } from '../../models/Setups/VerifiyUser';
import LookUpUserByPhone from './LookUpUserByPhone';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import PanToolIcon from '@mui/icons-material/PanTool';
import DoNotTouchIcon from '@mui/icons-material/DoNotTouch';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import HistoryIcon from '@mui/icons-material/History';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import UserConoAndPhoneModel, { UserConoAndPhone } from '../../models/UserConoAndPhone';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default observer(function UserListDataGrid() {
    const { userStore } = useStore();
    const { loadUsers, getAllUsers, loadingUsers, setEditUser } = userStore;
    const [open, setOpen] = React.useState(false);
    const [selectedUsername, setSelectedUsername] = React.useState('');
    const [refreshKey, setRefreshKey] = React.useState(0);
    //const [openUserReponses, setOpenUserResponses] = React.useState(-1);
    const cono = window.localStorage.getItem('cono');
    const [pageSize, setPageSize] = React.useState<number>(20);
    const [lookUpPhoneOpen, setLookUpPhoneOpen] = React.useState(false);
    const [error, setError] = React.useState('');
    const [showTextConfirmation, setShowTextConfirmation] = React.useState(false);
    const [openAreYourSureSendShifts, setOpenAreYourSureSendShifts] = React.useState(false);
    const [selectedSendUserShifts, setSelectedSendUserShift] = React.useState(new UserConoAndPhoneModel());

    const handleEditUserClick = (row: GridRenderCellParams<any, any, any>) => {
        history.push("/edituser/" + cono + '/' + row.row.id + '/');
        history.go(0);
    }

    const handleSendUserShifts = () => {
        setOpenAreYourSureSendShifts(false);
        userStore.sendviewshiftlink(selectedSendUserShifts).catch(error => setError('Phone Number is invalid or not found')).then((response) => {
            if (response === false) {
                console.log('invalid phone number');
                setError('Phone Number is invalid or not found');
            } else {
                setError('');
                setShowTextConfirmation(true);
            }
        });
    }

    const handleCloseSendShifts = () => {
        setOpenAreYourSureSendShifts(false);
    }

    const handleSendUserShiftsClick = (row: GridRenderCellParams<any, any, any>) => {
        // history.push("/edituser/" + cono + '/' + row.row.id + '/');
        // history.go(0);
        let u: UserConoAndPhone = { phone: row.row.phone, secret: 'zxcui7asd1' };
        setSelectedSendUserShift(u);
        setOpenAreYourSureSendShifts(true);
        //handleSendUserShifts(row);
    }

    const handleClickGoToCreateNewUser = () => {
        history.push("/createuser/");
        history.go(0);
    }

    const handleViewSentHistory = (row: GridRenderCellParams<any, any, any>) => {
        history.push("/usersenthistory/" + row.row.id + '/');
        history.go(0);
    }

    const handleClickGoToLookUpUser = () => {
        console.log('look up');
        setLookUpPhoneOpen(true);
    }
    const handleCloseLookUpUser = () => {
        setLookUpPhoneOpen(false);
    }

    const refreshUserListOnLookUpUser = () => {
        setLookUpPhoneOpen(false);
        loadUsers();
    }

    const columns: GridColDef[] = [
        { field: 'username', headerName: 'Username', flex: 1, minWidth: 100, headerClassName: 'super-app-theme--header' },
        { field: 'firstname', headerName: 'First Name', flex: .5, minWidth: 80, headerClassName: 'super-app-theme--header' },
        { field: 'lastname', headerName: 'Last Name', flex: .6, minWidth: 80, headerClassName: 'super-app-theme--header' },
        { field: 'employeeid', headerName: 'Employee ID', flex: .4, minWidth: 60, headerClassName: 'super-app-theme--header' },        
        { field: 'isagency', headerName: 'Agency', flex: .4, minWidth: 60, headerClassName: 'super-app-theme--header',hide:true }, 
        { field: 'credentials', headerName: 'Credentials', flex: .4, minWidth: 60, headerClassName: 'super-app-theme--header', hide: true },
        { field: 'email', headerName: 'Email', flex: 1, minWidth: 100, headerClassName: 'super-app-theme--header',hide:true },
        { field: 'role', headerName: 'Role(s)', flex: .7, minWidth: 80, headerClassName: 'super-app-theme--header' },
        { field: 'phone', headerName: 'Phone', flex: .7, minWidth: 80, headerClassName: 'super-app-theme--header' },
        { field: 'optinemail', headerName: 'Opt In Email', minWidth: 40, headerClassName: 'super-app-theme--header' },
        { field: 'optintext', headerName: 'Opt In Text', minWidth: 40, headerClassName: 'super-app-theme--header' },
        {
            field: "edit",
            headerName: "",
            sortable: false,
            maxWidth: 35,

            headerClassName: 'super-app-theme--header',
            renderCell: (row) =>
                <Tooltip title="Edit User">
                    <IconButton aria-label="edit" onClick={() => handleEditUserClick(row)}>
                        <EditIcon color='secondary' />
                    </IconButton>
                </Tooltip>
            // <Button onClick={() => handleEditUserClick(row)} color='secondary' variant='contained' className="btn btn-sm btn-danger btn-delete-user">
            //     <span>Edit</span>
            // </Button>,
        },
        {
            field: "sendschedule",
            headerName: "",
            sortable: false,
            maxWidth: 35,

            headerClassName: 'super-app-theme--header',
            renderCell: (row) => {
                return row.row.optintext == true ?
                    <Tooltip title="Send User's Shifts">
                        <IconButton aria-label="send" onClick={() => handleSendUserShiftsClick(row)}>
                            <SendToMobileIcon color='warning' />
                        </IconButton>
                    </Tooltip>
                    :
                    <></>
            }
            // <Button onClick={() => handleEditUserClick(row)} color='secondary' variant='contained' className="btn btn-sm btn-danger btn-delete-user">
            //     <span>Edit</span>
            // </Button>,
        },
        {
            field: "history",
            headerName: "",
            sortable: false,
            maxWidth: 35,

            headerClassName: 'super-app-theme--header',
            renderCell: (row) =>
                <Tooltip title="Message History">
                    <IconButton aria-label="history" onClick={() => handleViewSentHistory(row)}>
                        <HistoryIcon color='warning' />
                    </IconButton>
                </Tooltip>
            // <Button onClick={() => handleEditUserClick(row)} color='secondary' variant='contained' className="btn btn-sm btn-danger btn-delete-user">
            //     <span>Edit</span>
            // </Button>,
        },
        {
            field: "inactivate",
            headerName: "",
            sortable: false,
            maxWidth: 35,

            headerClassName: 'super-app-theme--header',
            renderCell: (row) =>
                <Tooltip title="Inactivate User">
                    <IconButton aria-label="edit" onClick={() => handleClickOpen(row)}>
                        <BlockIcon color='error' />
                    </IconButton>
                </Tooltip>
            // <Button onClick={() => handleClickOpen(row)} color='error' variant='contained' sx={{ paddingLeft: 5, paddingRight: 5 }} className="btn btn-sm btn-danger btn-delete-user">
            //     Inactivate
            // </Button>,
        },
        {
            field: "verify",
            headerName: "",
            sortable: false,
            maxWidth: 35,

            headerClassName: 'super-app-theme--header',
            renderCell: (row) => {
                if (!row.row.accountverifiedbycompany) {
                    return (
                        // <Button color='warning' variant='contained' className="btn btn-sm btn-danger btn-delete-user"
                        //     onClick={() => {
                        //         handleClickVerify(row);
                        //     }}>
                        //     <span>Verify</span>
                        // </Button>
                        <Tooltip title="Verify User">
                            <IconButton aria-label="edit" onClick={() => handleClickVerify(row)}>
                                <HowToRegIcon color='warning' />
                            </IconButton>
                        </Tooltip>
                    );
                } else {
                    if (row.row.onhold) {
                        return (
                            // <Tooltip title="Take User Off Hold">
                            //     <Button color='success' variant='contained' className="btn btn-sm btn-danger btn-delete-user"
                            //         onClick={() => {
                            //             handleClickPutOffHold(row);
                            //         }}>
                            //         <span>UnHold</span>
                            //     </Button>
                            // </Tooltip>
                            <Tooltip title="Take User Off Hold">
                                <IconButton aria-label="edit" onClick={() => handleClickPutOffHold(row)}>
                                    <DoNotTouchIcon color='success' />
                                </IconButton>
                            </Tooltip>
                        )
                    } else {
                        return (
                            // <Tooltip title="Put User On Hold">
                            //     <Button color='primary' variant='contained' className="btn btn-sm btn-danger btn-delete-user"
                            //         startIcon={<PanToolIcon />}
                            //         onClick={() => {
                            //             handleClickPutOnHold(row);
                            //         }}>
                            //         {/* <span>Hold</span> */}
                            //     </Button>
                            // </Tooltip>
                            <Tooltip title="Put User On Hold">
                                <IconButton aria-label="edit" onClick={() => handleClickPutOnHold(row)}>
                                    <PanToolIcon color='primary' />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                }
            }
        },
    ];

    const handleClickVerify = (row: GridRenderCellParams<any, any, any>) => {
        console.log(row.row.id);
        const cono = window.localStorage.getItem('cono');
        if (cono === null) {
            return;
        }
        if (window.localStorage.getItem('user') === null) {
            return;
        }
        let user: VerifyUser = { cono: cono, username: row.row.id, verifiedbyusername: window.localStorage.getItem('user')! };
        console.log('try to verify ' + row.row.id + ' by ' + window.localStorage.getItem('user'));
        userStore.verifyUser(user).then(() => {
            setSelectedUsername('');
            setRefreshKey(refreshKey + 1);
            setOpen(false);
        });
    }
    const handleClickOpen = (row: GridRenderCellParams<any, any, any>) => {
        setSelectedUsername(row.row.id);
        setOpen(true);
    };
    const handleClickPutOnHold = (row: GridRenderCellParams<any, any, any>) => {
        console.log('try to hold ' + row.row.id);
        userStore.putUserOnHold(row.row.id).then(() => {
            setSelectedUsername('');
            setRefreshKey(refreshKey + 1);
        });
    }
    const handleClickPutOffHold = (row: GridRenderCellParams<any, any, any>) => {
        userStore.putUserOffHold(row.row.id).then(() => {
            setSelectedUsername('');
            setRefreshKey(refreshKey + 1);
        });
    }

    const handleClickGoToInactiveUsers = () => {
        history.push("/inactiveusers");
        history.go(0);
    }


    const myrows = getAllUsers.map((user) => {
        if(user.isagency){
        return {        
            id: user.username,
            username:'(a) ' + user.username,
            email: user.email,
            firstname: user.firstname,
            lastname: user.lastname,
            credentials: user.credentials,
            role: user.Roles,
            phone: user.phone,
            optinemail: user.optinemail,
            optintext: user.optintext,
            accountverifiedbycompany: user.accountverifiedbycompany,
            onhold: user.onhold,
            employeeid: user.employeeid,
            isagency:user.isagency,
        }
    }
        else{
            return {        
                id: user.username,
                username:user.username,
                email: user.email,
                firstname: user.firstname,
                lastname: user.lastname,
                credentials: user.credentials,
                role: user.Roles,
                phone: user.phone,
                optinemail: user.optinemail,
                optintext: user.optintext,
                accountverifiedbycompany: user.accountverifiedbycompany,
                onhold: user.onhold,
                employeeid: user.employeeid,
                isagency:user.isagency,
            }
        }
    })

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        document.title = 'Users';
        async function fetchData() {
            loadUsers();
        }

        fetchData();
    }, [refreshKey]);

    const deleteUser = () => {
        console.log('ref ' + refreshKey);
        userStore.inactivateUser(selectedUsername).then(() => {
            setSelectedUsername('');
            setRefreshKey(refreshKey + 1);
            setOpen(false);
        });
        console.log('refy ' + refreshKey);
    }

    if (loadingUsers) return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography fontSize={30} textAlign="center" sx={{ fontWeight: 'bold', marginTop: 1 }}>
                    USERS
                </Typography>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            </Stack>
        </div>
    )

    return (
        <div>
            <div>
                <Box sx={{ width: '100%' }}>
                    <Typography variant='h4' fontSize={30} align='center' textAlign="center" sx={{ fontWeight: 'bold', marginTop: 1 }}>
                        USERS
                    </Typography>
                    {/* <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2">Add User</Link> */}
                    <Stack direction={'row'} sx={{ justifyContent: 'flex-end' }}>
                        <Button
                            sx={{ margin: 1, borderRadius: 5 }}
                            variant="contained"
                            color="primary" onClick={handleClickGoToCreateNewUser}>
                            Create New User
                        </Button>
                        <Button
                            sx={{ margin: 1, borderRadius: 5 }}
                            variant="contained"
                            color="secondary" onClick={handleClickGoToLookUpUser}>
                            Look Up User
                        </Button>
                        <Button
                            sx={{ margin: 1, borderRadius: 5 }}
                            variant="contained"
                            color="warning" onClick={handleClickGoToInactiveUsers}>
                            Inactive Users
                        </Button>
                    </Stack>
                </Box>
                <Box
                    sx={{
                        height: 300,
                        width: '100%',
                        '& .super-app-theme--header': {
                            backgroundColor: blue[700],
                            fontWeight: 'bold',
                            color: 'white',
                        },
                        marginBottom: 15,
                    }}
                >
                    <DataGrid sx={{
                        '.MuiDataGrid-columnSeparator': {
                            display: 'none',
                        },
                        '&.MuiDataGrid-root': {
                            border: 'none',
                        },
                        overflowX: 'scroll',
                    }} autoHeight={true} columns={columns} rows={myrows} pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20, 50, 100]} disableSelectionOnClick={true}></DataGrid>
                    {/* <Button sx={{ margin: 1 }}
                    variant="contained"
                    color="primary"
                    component={Link} to="/createuser" onClick={() => setEditUser(null)}>
                    Create New User
                </Button> */}
                </Box>

            </div>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Are You Sure?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want inactivate this user?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>No</Button>
                        <Button onClick={deleteUser} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openAreYourSureSendShifts}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Are You Sure?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want send this user a link to view their schedule and open shifts?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseSendShifts}>No</Button>
                        <Button onClick={handleSendUserShifts} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={lookUpPhoneOpen}
                    onClose={handleCloseLookUpUser}
                    fullWidth={true}
                    maxWidth={'sm'}>
                    <LookUpUserByPhone handleCloseOnSave={refreshUserListOnLookUpUser}></LookUpUserByPhone>
                </Dialog>
            </div>
        </div>
    );
})