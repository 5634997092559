import React, { useEffect } from 'react';
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Button, InputLabel, TextField, Checkbox, FormGroup, FormControlLabel, Grid, Tooltip } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { Stack, Box } from '@mui/system';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns'
import { blue } from '@mui/material/colors';
import { history } from '../../..';
import { DataGrid, GridRowsProp, GridColDef, GridComparatorFn, GridRowParams, GridSelectionModel, GridCellParams } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LoadingButton } from '@mui/lab';
import { UserShiftResponse } from '../../models/UserShiftResponse';
import ConfirmShiftsDialog from '../../components/ConfirmShiftsDialog';
import dayjs from 'dayjs';
import ViewListIcon from '@mui/icons-material/ViewList';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Scheduler } from '@aldabil/react-scheduler';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const dayInMonthComparator: GridComparatorFn<Date> = (v1, v2) =>
    new Date(v1).valueOf() - new Date(v2).valueOf();


const columns: GridColDef[] = [
    {
        field: 'shiftdate', headerName: 'Shift Date', flex: .5, minWidth: 110, headerClassName: 'super-app-theme--header',
        type: 'date', sortComparator: dayInMonthComparator,
    },
    { field: 'shift', headerName: 'Shift Time', flex: .8, minWidth: 110, headerClassName: 'super-app-theme--header', sortable: false },
    { field: 'name', headerName: 'Name', flex: 1, minWidth: 110, headerClassName: 'super-app-theme--header' },
    { field: 'username', headerName: 'Username', flex: 1, minWidth: 100, headerClassName: 'super-app-theme--header' },
    // {
    //     field: 'responsedate', headerName: 'Response Date', flex: 1, minWidth: 100, headerClassName: 'super-app-theme--header',
    //     type: 'date', sortComparator: dayInMonthComparator,

    // },
    { field: 'descr', headerName: 'Description', flex: 1, minWidth: 110, headerClassName: 'super-app-theme--header' },
    { field: 'roles', headerName: 'Role(s)', flex: .6, minWidth: 85, headerClassName: 'super-app-theme--header' },
    {
        field: 'hours', headerName: 'Hours In Week',  headerClassName: 'super-app-theme--header', sortable:false,
        type: 'number'
    },
    // { field: 'responsefromtemplate', headerName: 'Auto Assigned', flex: .6, minWidth: 80, headerClassName: 'super-app-theme--header' },
    { field: 'accepted', headerName: 'Claimed', flex: .6, minWidth: 90, headerClassName: 'super-app-theme--header' },
    { field: 'confirmed', headerName: 'Confirmed', flex: .6, minWidth: 90, headerClassName: 'super-app-theme--header' },
    {
        field: 'shiftalertid', hide: true, headerName: 'shiftalertid', flex: .5, headerClassName: 'super-app-theme--header',
        type: 'number'
    },
];

export default observer(function ResponsesList() {
    const { userStore, shiftStore } = useStore();
    const { loadResponses, getUserResponses, loading, setEditUser, getUserResponsesForCalendarView } = userStore;
    const { confirmShiftsNoMessage, confirmShiftsSingleMessage, confirmShiftsMultipleMessages, sendingShiftAlerts } = shiftStore;
    const [open, setOpen] = React.useState(false);
    const [selectedUsername, setSelectedUsername] = React.useState('');
    const [refreshKey, setRefreshKey] = React.useState(0);
    const [openUserReponses, setOpenUserResponses] = React.useState(-1);
    const cono = window.localStorage.getItem('cono');
    const [pageSize, setPageSize] = React.useState<number>(20);
    const today = new Date();
    const daysago = today.setDate(today.getDate() - 2);
    const [afterDate, setAfterDate] = React.useState(new Date(daysago));
    const [hideDenied, setHideDenied] = React.useState(false);

    const [selectConfirmationOpen, setSelectConfirmationOpen] = React.useState(false);
    const [selectConfirmationValue, setSelectConfirmationValue] = React.useState(3); //0 = send individual alerts, 1 = send ONE alert per user, 2 = send nothing, 3 = dont confirm bc the user canceled the dialog

    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
    const [showListView, setShowListView] = React.useState(true);
    // const handleClickOpen = (username) => {
    //     setSelectedUsername(username);
    //     setOpen(true);
    // };

    // const handleEditUserClick = (username) => {
    //     history.push("/edituser/" + cono + '/' + username + '/');
    //     history.go(0);
    // }
    const clickShowListView = () => {
        setShowListView(true);
    }

    const clickShowCalendarView = () => {
        setShowListView(false);
    }

    const handleCloseConfirmationSelection = (value: number) => {
        setSelectConfirmationValue(value);
        setSelectConfirmationOpen(false);
        switch (value) {
            case 0:
                // console.log('send indivdual messages');
                // console.log('send 1 messge per user');
                const selectedidsmultiple = new Set(selectionModel);
                // console.log(selectedidsmultiple.size);
                const selectedmultiple = myrows.filter(x => selectedidsmultiple.has(x.row));
                // console.log(selectedmultiple.length);
                // selected.forEach((r) => {
                //     console.log('r is ' + r.shiftalertid + ' ' + r.username);

                //     //console.log(getUserResponses.at(Number(r))?.shiftdate);
                // })
                console.log('try sending ' + JSON.stringify(selectedmultiple));
                confirmShiftsMultipleMessages(JSON.stringify(selectedmultiple)).then(() => {
                    setSelectionModel([]);
                    setRefreshKey(refreshKey + 1);
                });
                break;
            case 1:
                // console.log('send 1 messge per user');
                const selectedidssingle = new Set(selectionModel);
                // console.log(selectedidssingle.size);
                const selectedsingle = myrows.filter(x => selectedidssingle.has(x.row));
                // console.log(selectedsingle.length);
                // selected.forEach((r) => {
                //     console.log('r is ' + r.shiftalertid + ' ' + r.username);

                //     //console.log(getUserResponses.at(Number(r))?.shiftdate);
                // })
                // console.log('try sending ' + JSON.stringify(selectedsingle));
                confirmShiftsSingleMessage(JSON.stringify(selectedsingle)).then(() => {
                    setSelectionModel([]);
                    setRefreshKey(refreshKey + 1);
                });
                break;
            case 2:
                // console.log('save but dont send a message');
                const selectedids = new Set(selectionModel);
                // console.log(selectedids.size);
                const selected = myrows.filter(x => selectedids.has(x.row));
                // console.log(selected.length);
                // selected.forEach((r) => {
                //     console.log('r is ' + r.shiftalertid + ' ' + r.username);

                //     //console.log(getUserResponses.at(Number(r))?.shiftdate);
                // })
                // console.log('try sending ' + JSON.stringify(selected));
                confirmShiftsNoMessage(JSON.stringify(selected)).then(() => {
                    setSelectionModel([]);
                    setRefreshKey(refreshKey + 1);
                });
                break;
            case 3:
                // console.log('cancel and dont do anything');
                break;
            default:
                // console.log('cancel and dont do anything 2');
                break;
        }
    }

    function excludeDeniedFilter(stopped: UserShiftResponse) {
        if (hideDenied) {
            return stopped.shiftaccepted === true;
        }
        else {
            return stopped.shiftaccepted === false || stopped.shiftaccepted === true;
        }
    }

    const myrows = getUserResponses.filter(excludeDeniedFilter).filter(s => s.shiftalertid > -1).map((user) => {
        return {
            id: user.responsedate,
            username: user.username,
            name: user.name,
            responsedate: format(new Date(user.responsedate), 'MM/dd/yyyy hh:mm a'),
            shiftdate: format(new Date(user.shiftdate), 'MM/dd/yyyy'),
            shift: format(new Date(user.shiftstarttime), "hh:mm a") + ' - ' + format(new Date(user.shiftendtime), "hh:mm a"),
            descr: user.descr,
            accepted: user.shiftaccepted,
            confirmed: user.confirmed,
            shiftalertid: user.shiftalertid,
            cono: user.cono,
            row: user.Row,
            confirmedbyusername: window.localStorage.getItem('user'),
            responsefromtemplate: user.responsefromtemplate,
            roleid: user.roleid,
            roles: user.roles,
            hours: user.hours,
        }
    })

    const handleConfirmShiftsClick = () => {
        // console.log('click confirm for ' + selectionModel.length);
        // selectionModel.forEach((r) => {
        //     console.log('confirm shiftid ' + r);
        // })
        if (selectionModel.length > 0) {
            setSelectConfirmationValue(3);
            setSelectConfirmationOpen(true);
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const isMyRowSelectable = (params: GridRowParams) => {
        if (params.row.confirmed === false && params.row.accepted === true) {
            return true;
        } else {
            return false;
        }
    }

    const getConfirmedCellStyle = (params: GridCellParams<boolean>) => {
        if (params.row.confirmed === false && params.row.accepted === true) {
            return 'notconfirmed';
        }
        else {
            return '';
        }
    }

    useEffect(() => {
        document.title = 'Responses';
        async function fetchData() {
            loadResponses(afterDate);
        }

        fetchData();
    }, [refreshKey, afterDate]);

    const handleChange = () => {

    };

    if (loading) return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography fontSize={30} textAlign="center" sx={{ fontWeight: 'bold', marginTop: 1 }}>
                    RESPONSES
                </Typography>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            </Stack>
        </div>
    )

    return (
        <>
            <div>
                <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography fontSize={30} textAlign="center" sx={{ fontWeight: 'bold', marginTop: 1 }}>
                        RESPONSES
                    </Typography>
                    <Stack direction='row'>
                        <Tooltip title="List View">
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={clickShowListView}>
                                {showListView ? <ViewListIcon fontSize='large' color='primary' /> : <ViewListIcon fontSize='medium' />}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Calendar View">
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={clickShowCalendarView}>
                                {showListView ? <CalendarTodayIcon fontSize='medium' /> : <CalendarTodayIcon fontSize='large' color='primary' />}
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>
                {showListView ?
                    <>
                        <Box style={{ flexGrow: 1 }}>
                            <Grid container>
                                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginBottom: 1 }}>
                                    {/* <Box
                                sx={{
                                    marginBottom: 1,
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    verticalAlign: 'center'
                                }}
                            > */}
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Shifts After"
                                            value={dayjs(afterDate)}
                                            onChange={(newValue) => {
                                                if (newValue == null) {
                                                    return;
                                                }
                                                setAfterDate(newValue.toDate());
                                            }}

                                        />
                                    </LocalizationProvider>
                                    <LoadingButton
                                        sx={{
                                            marginLeft: 1, alignContent: 'center',
                                            alignItems: 'center',
                                            verticalAlign: 'center',
                                            borderRadius: 5
                                        }}
                                        variant="contained"
                                        color="warning"
                                        onClick={handleConfirmShiftsClick}
                                        loading={sendingShiftAlerts}
                                        disabled={selectionModel.length <= 0}>
                                        Confirm Shift(s)
                                    </LoadingButton>
                                    {/* </Box> */}
                                </Grid>
                                <Grid item xs sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <Box >
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={hideDenied} onChange={e => { setHideDenied(e.target.checked) }} />} label="Hide Denied Shifts" />
                                        </FormGroup>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            sx={{
                                height: 300,
                                width: '100%',
                                '& .super-app-theme--header': {
                                    backgroundColor: blue[700],
                                    fontWeight: 'bold',
                                    color: 'white',
                                },
                                marginBottom: 15,
                            }}
                        >

                            <DataGrid sx={{
                                '.MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                                '&.MuiDataGrid-root': {
                                    border: 'none',
                                },
                                '& .notconfirmed': {
                                    backgroundColor: '#ffb74d',
                                    color: '#1a3e72',
                                },
                                '& .confirmed': {
                                    backgroundColor: '#aed581',
                                    color: '#1a3e72',
                                },
                                marginBottom: 15
                            }}
                                getRowId={(row) => row.row}
                                autoHeight={true} columns={columns} rows={myrows} pageSize={pageSize}
                                checkboxSelection={true}
                                isRowSelectable={(params: GridRowParams) => isMyRowSelectable(params)} //need to make sure claimed is true
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                                selectionModel={selectionModel}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)} rowsPerPageOptions={[10, 20, 50, 100]}
                                disableSelectionOnClick={false}
                                getCellClassName={(params: GridCellParams<boolean>) => {
                                    if (params.field === 'confirmed') {
                                        return getConfirmedCellStyle(params);
                                    } else {
                                        return '';
                                    }
                                }}></DataGrid>
                        </Box></> :
                    <>
                        <div style={{ width: '100%', margin: 5 }}>
                            <Scheduler
                                editable={false}
                                deletable={false}
                                selectedDate={new Date()}
                                view="month"
                                agenda={false}
                                week={{
                                    weekDays: [0, 1, 2, 3, 4, 5, 6],
                                    weekStartOn: 0,
                                    startHour: 0,
                                    endHour: 24,
                                    step: 180
                                }}
                                month={{
                                    weekDays: [0, 1, 2, 3, 4, 5, 6],
                                    weekStartOn: 0,
                                    startHour: 0,
                                    endHour: 24,
                                }}
                                day={{
                                    startHour: 0,
                                    endHour: 24,
                                    step: 120
                                }}
                                events={getUserResponsesForCalendarView}
                            />
                        </div>
                    </>}

                <ConfirmShiftsDialog
                    selectedValue={selectConfirmationValue}
                    open={selectConfirmationOpen}
                    onClose={handleCloseConfirmationSelection} />
            </div>
        </>
    );
})