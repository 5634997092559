import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Button, FormControlLabel, Grid, Tooltip, Switch } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { format } from 'date-fns'
import LinearProgress from '@mui/material/LinearProgress';
import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';
import SelectRoleDialog from '../../components/SelectRoleDialog';
import ShiftListFilterDialog from '../../components/ShiftListFilterDialog';
import { SendShiftAlertForRoleModel } from '../../models/SendShiftAlertForRole';
import LoadingButton from '@mui/lab/LoadingButton';
import HistoryIcon from '@mui/icons-material/History';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ViewListIcon from '@mui/icons-material/ViewList';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Scheduler } from '@aldabil/react-scheduler';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ShiftAlertForSingleUser } from '../../models/ShiftAlertForSingleUser';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateShiftAlertDialog from "../shifts/CreateShiftAlertDialog";

// const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//         backgroundColor: theme.palette.info.light,
//         color: theme.palette.common.white,
//     },
//     [`&.${tableCellClasses.body}`]: {
//         fontSize: 12,
//     },
// }));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
    },
}));

const StyledTableCellAlt = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3A99FC',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default observer(function ShiftList() {
    const { shiftStore, userStore } = useStore();
    const { shiftRegistry, loadShifts, getAllShifts, resendShiftAlert, getAllShiftResponses, loading, putShiftAlertStopResponses,
        sendingShiftAlerts, getAllShiftsForSchedulerView, loadUserRecommendationsForShifts, getUserRecommendations,
        sendShiftAlerttoOneUser, removeUserShiftResponse } = shiftStore;
    const { getCompanyRoles } = userStore;
    const [open, setOpen] = React.useState(false);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openAreYouSureRemoveResponse, setOpenAreYouSureRemoveResponse] = React.useState(false);
    const [selectedRemoveResponse, setSelectedRemoveResponse] = React.useState(null);
    const [selectedShiftAlertid, setSelectedShiftAlertId] = React.useState('');
    const [openHistory, setOpenHistory] = React.useState(-1);
    const [openStopAlert, setOpenStopAlert] = React.useState(false);
    const [openStartAlert, setOpenStartAlert] = React.useState(false);
    const [selectRoleOpen, setSelectRoleOpen] = React.useState(false);
    const [selectedRoleValue, setSelectedRoleValue] = React.useState('');
    const [refreshKey, setRefreshKey] = React.useState(0);
    const [hideStopped, setHideStopped] = React.useState(JSON.parse(window.localStorage.getItem('hidestoppedshifts')));
    const [showShiftRecommendations, setShowShiftRecommendations] = React.useState(false);
    const [showCreateShiftAlertDialog, setShowCreateShiftAlertDialog] = React.useState(false);
    const [createShiftAlertSelectedRole, setCreateShiftAlertSelectedRole] = React.useState(-1);
    const [createShiftAlertSelectedDate, setCreateShiftAlertSelectedDate] = React.useState(new Date());

    const [fromDate, setFromDate] = React.useState(new Date());
    const today = new Date();
    const futuredate = today.setDate(today.getDate() + 30);
    const [thruDate, setThruDate] = React.useState(new Date(futuredate));
    const [showListView, setShowListView] = React.useState(true);

    const [showFilterDialog, setShowFilterDialog] = React.useState(false);

    const clickShowFilterDialog = () => {
        setShowFilterDialog(true);
    }
    const hideFilterDialog = (value, from, thru) => {
        setHideStopped(value);
        console.log('set storage ' + value);
        window.localStorage.setItem('hidestoppedshifts',value);
        setFromDate(from);
        setThruDate(thru);
        setShowFilterDialog(false);
    }

    const clickShowListView = () => {
        setShowListView(true);
    }

    const clickShowCalendarView = () => {
        setShowListView(false);
    }

    const handleClickOpen = (shiftalertid) => {
        console.log('test ' + shiftalertid);
        setSelectedShiftAlertId(shiftalertid);
        setOpen(true);
    };
    const onCloseCreateShiftAlertDialog = () =>{
        setShowCreateShiftAlertDialog(false);
        setRefreshKey(refreshKey + 1);
    }
    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseYes = () => {
        let shift = { cono: window.localStorage.getItem('cono'), shiftalertid: selectedShiftAlertid, sentbyusername: window.localStorage.getItem('user') };
        console.log("try to resend alert for " + selectedShiftAlertid);
        resendShiftAlert(shift);
        setOpen(false);
        setOpenSuccess(true);
        setRefreshKey(refreshKey + 1);
    }
    const handleCloseSnack = () => {
        setOpenSuccess(false);
    }

    const handleClickOpenStopAlert = (shiftalertid) => {
        setSelectedShiftAlertId(shiftalertid);
        setOpenStopAlert(true);
    }
    const handleCloseStopAlert = () => {
        setOpenStopAlert(false);
    };
    const handleCloseStopAlertYes = () => {
        setOpenStopAlert(false);
        let response = { cono: window.localStorage.getItem('cono'), shiftalertid: selectedShiftAlertid, stopresponses: true };
        putShiftAlertStopResponses(response).then(() => {
            loadShifts();
        })
    }

    const handleClickOpenStartAlert = (shiftalertid) => {
        setSelectedShiftAlertId(shiftalertid);
        setOpenStartAlert(true);
    }
    const handleCloseStartAlert = () => {
        setOpenStartAlert(false);
    };
    const handleCloseStartAlertYes = () => {
        setOpenStartAlert(false);
        let response = { cono: window.localStorage.getItem('cono'), shiftalertid: selectedShiftAlertid, stopresponses: false };
        putShiftAlertStopResponses(response).then(() => {
            loadShifts();
            setRefreshKey(refreshKey + 1);
        })
    }

    function getSchedulerColorByRanking(ranking) {
        if (ranking === 0) {
            return '#54d43a';
        }
        else if (ranking === 1) {
            return '#f27b40';
        }
        else {
            return '#f2d440';
        }
    }

    const handleClickRemoveResponse = (response) => {
        setSelectedRemoveResponse(response);
        setOpenAreYouSureRemoveResponse(true);
    }

    const handleClickYesRemoveResponse = () => {
        console.log('try to remove ' + selectedRemoveResponse.shiftalertid + ' ' + selectedRemoveResponse.username);
        let resp = { cono: window.localStorage.getItem('cono'), shiftalertid: selectedRemoveResponse.shiftalertid, username: selectedRemoveResponse.username };
        setOpenAreYouSureRemoveResponse(false);
        removeUserShiftResponse(resp).then(() => {
            setRefreshKey(refreshKey + 1);
        });
    }
    const handleClickNoRemoveResponse = () => {
        setOpenAreYouSureRemoveResponse(false);
    }

    const handleClickSendCurrentShifts = () => {
        console.log("select a role and send the shift key links");
        setSelectRoleOpen(true);
    }
    const handleCloseSelectedRole = (value) => {
        setSelectRoleOpen(false);
        setSelectedRoleValue(value);
        console.log('try to send alerts for role ' + value);
        if (value || value === 0) {
            let cono = window.localStorage.getItem('cono');
            if (!cono) {
                return
            }
            let user = window.localStorage.getItem('user');
            if (!user) {
                user = "";
            }
            let shift = new SendShiftAlertForRoleModel()
            shift.cono = cono;
            shift.roleid = value;
            shift.username = user;
            shiftStore.createAndSendKeysForRole(shift).then(() => {
                setOpenSuccess(true);
                setSelectedRoleValue('');
                setRefreshKey(refreshKey + 1);
            });
        }
    }

    const handleSendShiftToUser = (shift, user) => {
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }
        let loggedinuser = window.localStorage.getItem('user');
        if (!loggedinuser) {
            loggedinuser = "";
        }
        let u = new ShiftAlertForSingleUser();
        u.cono = cono;
        u.shiftalertid = shift.shiftalertid;
        u.sentbyusername = loggedinuser;
        u.sendtousername = user.username;
        sendShiftAlerttoOneUser(u).then(() => {
            console.log('did it work');
        });
    }

    useEffect(() => {
        document.title = 'Shifts';
        async function fetchData() {
            let hidestoppedshifts =  JSON.parse(window.localStorage.getItem('hidestoppedshifts'));
            console.log('we found stopped ' + hidestoppedshifts);
            if(hidestoppedshifts != null){
                setHideStopped(hidestoppedshifts);
            }
            console.log('found is set ' + hideStopped);
            loadShifts();
            const daysago = today.setDate(today.getDate() - 60);
            loadUserRecommendationsForShifts(format(new Date(daysago), 'MMddyyyy'), format(new Date(), 'MMddyyyy'));
        }
        //loadCompanyRolesWithLastSent();
        fetchData();
    }, [shiftRegistry.size, loadShifts, refreshKey]);

    function excludeRoles(shift) {
        if (getCompanyRoles == null) {
            return true;
        }
        else {
            for (const each of getCompanyRoles) {
                if (shift.roleid === each.roleid) {
                    return each.isSelected;
                }
            }
        }
    }

    function excludeStoppedFilter(stopped) {
        if (hideStopped) {
            return stopped.stopresponses === false;
        }
        else {
            return stopped.stopresponses === false || stopped.stopresponses === true;
        }
    }

    function excludeRecommendedUsersWhoClaimedTheShift(user) {
        let result = true;
        getAllShiftResponses.forEach((shift) => {
            if (user.shiftalertid === shift.shiftalertid && user.username === shift.username && shift.shiftaccepted === true) {
                result = false;
            }
        })
        return result;
    }

    function onlyShowInDateRange(range) {
        const from = new Date(fromDate);
        from.setHours(0, 0, 0, 0);
        const thru = new Date(thruDate);
        thru.setHours(23, 59, 59, 59);
        if (new Date(range.shiftdate) >= from && new Date(range.shiftdate) <= thru) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleChange = (event) => {
        setShowShiftRecommendations(event.target.checked);
    };

    // const handleRefresh = () =>{
    //     console.log("try to refresh" + fromDate + " thru " + thruDate);
    // }

    // useEffect(() => {
    //     loadShifts();
    // }, []);

    if (loading) return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography fontSize={30} textAlign="center" sx={{ fontWeight: 'bold', marginTop: 1 }}>
                    SHIFTS
                </Typography>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            </Stack>
        </div>
    )

    return (
        <div sx={{ paddingBottom: 50 }}>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {/* <h1 style={{
                    textAlign: 'center', fontSize: '18',
                    fontWeight: 700,
                    color: 'inherit',
                }}>SHIFTS</h1> */}
                <Typography fontSize={30} textAlign="center" sx={{ fontWeight: 'bold', marginTop: 1 }}>
                    SHIFTS
                </Typography>
                <Stack direction='row' sx={{ marginLeft: 29 }}>
                    <Tooltip title="List View">
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={clickShowListView}>
                            {showListView ? <ViewListIcon fontSize='large' color='primary' /> : <ViewListIcon fontSize='medium' />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Calendar View">
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={clickShowCalendarView}>
                            {showListView ? <CalendarTodayIcon fontSize='medium' /> : <CalendarTodayIcon fontSize='large' color='primary' />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Show recommended users for shifts based on tendancies to claim similar shifts" align='end'>
                        <FormControlLabel sx={{ margin: 1 }} control={<Switch checked={showShiftRecommendations} onChange={handleChange} height='30' />} label="Show Recommendations" />
                    </Tooltip>

                </Stack>
            </Stack>
            {showListView ?
                <>
                    <Box style={{ flexGrow: 1 }}>
                        <Grid container>
                            <Grid item xs={11}>
                                <Stack direction={'row'}>
                                    <LoadingButton
                                        sx={{ margin: 1, textAlign: 'center', borderRadius: 5 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setShowCreateShiftAlertDialog(true)}
                                        loading={sendingShiftAlerts}>
                                        Create New Shift
                                    </LoadingButton>
                                    <LoadingButton
                                        sx={{ margin: 1, textAlign: 'center', borderRadius: 5 }}
                                        variant="contained"
                                        color="warning" onClick={handleClickSendCurrentShifts}
                                        loading={sendingShiftAlerts}>
                                        Send Current Shifts
                                    </LoadingButton>
                                    <LoadingButton
                                        sx={{ margin: 1, textAlign: 'center', borderRadius: 5 }}
                                        variant="contained"
                                        color="secondary"
                                        component={Link} to="/createshiftswithtemplate"
                                        loading={sendingShiftAlerts}>
                                        Create Shifts With Template
                                    </LoadingButton>
                                </Stack>
                            </Grid>
                            {/* <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', marginBottom: 1 }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        minWidth='550'
                                        label="From"
                                        value={dayjs(fromDate)}
                                        onChange={(newValue) => setFromDate(newValue)}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', marginBottom: 1 }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Thru"
                                        value={dayjs(thruDate)}
                                        onChange={(newValue) => setThruDate(newValue)}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Box >
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={hideStopped} onChange={e => { setHideStopped(e.target.checked) }} />} label="Hide Stopped Shifts" />
                                    </FormGroup>
                                </Box>
                            </Grid> */}
                            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Tooltip title="Filter Results" align='end'>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={clickShowFilterDialog}>
                                        <FilterListIcon fontSize='large' />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Box>
                    {/* <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2">Add User</Link> */}
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 270, tableLayout: 'auto' }} size='small' aria-label="simple table">
                            <TableHead>
                                <StyledTableRow key={-1}>
                                    <StyledTableCell width={40}></StyledTableCell>
                                    <StyledTableCell align="center">Shift</StyledTableCell>
                                    {/* <StyledTableCell align="center">Shift Start</StyledTableCell>
                            <StyledTableCell align="center">Shift End</StyledTableCell> */}
                                    {/* <StyledTableCell align="center">Overnight</StyledTableCell> */}
                                    <StyledTableCell align="center">Shift Description</StyledTableCell>
                                    <StyledTableCell align="center">Details</StyledTableCell>
                                    <StyledTableCell align="center">Openings Remaining</StyledTableCell>
                                    <StyledTableCell align="center">Number Of Openings</StyledTableCell>
                                    <StyledTableCell align="center">Role(s)</StyledTableCell>
                                    <StyledTableCell align="center">Last Sent Individually</StyledTableCell>
                                    <StyledTableCell align="center">Auto Confirm</StyledTableCell>
                                    <StyledTableCell width={40}></StyledTableCell>
                                    <StyledTableCell width={40}></StyledTableCell>
                                    <StyledTableCell width={40}></StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {getAllShifts.filter(excludeStoppedFilter).filter(onlyShowInDateRange).filter(excludeRoles).map(shift =>
                                    <React.Fragment>
                                        <StyledTableRow key={shift.shiftdate.toString() + shift.shiftalertid.toString()}>
                                            <TableCell width={40}>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => setOpenHistory(openHistory === shift.shiftalertid ? -1 : shift.shiftalertid)}
                                                >
                                                    {openHistory === shift.shiftalertid ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            </TableCell>
                                            <StyledTableCell align="center">{format(new Date(shift.shiftdate), 'MM/dd/yyyy')} {format(new Date(shift.shiftstarttime), "hh:mm a")} - {format(new Date(shift.shiftendtime), "hh:mm a")}</StyledTableCell>
                                            {/* <StyledTableCell align="center">{format(new Date(shift.shiftstarttime), "hh:mm a")}</StyledTableCell>
                                    <StyledTableCell align="center">{format(new Date(shift.shiftendtime), "hh:mm a")}</StyledTableCell> */}
                                            {/* <StyledTableCell align="center">{String(shift.shiftendsnextday)}</StyledTableCell> */}
                                            <StyledTableCell align="center">{shift.shiftdescr}</StyledTableCell>
                                            <StyledTableCell align="center">{shift.descr}</StyledTableCell>
                                            <StyledTableCell align="center">{String(shift.RemainingOpenShifts)}</StyledTableCell>
                                            <StyledTableCell align="center">{String(shift.numofopenings)}</StyledTableCell>
                                            <StyledTableCell align="center">{shift.roles}</StyledTableCell>
                                            <StyledTableCell align="center">{new Date(shift.lastsent).getFullYear() > 1901 ? format(new Date(shift.lastsent), 'MM/dd/yyyy hh:mm a') : 'Never'}</StyledTableCell>
                                            <StyledTableCell align="center">{String(shift.autoconfirm)}</StyledTableCell>
                                            <StyledTableCell width={40} align="center">
                                                {/* <Button width="20"
                                            variant="contained"
                                            color="secondary"
                                            component={Link} to={'/viewsenthistory/' + shift.cono + '/' + shift.shiftalertid}>
                                            HISTORY
                                        </Button> */}
                                                <Tooltip title="View Shift Alert Sent History">
                                                    <IconButton aria-label="history" component={Link} to={'/viewsenthistory/' + shift.cono + '/' + shift.shiftalertid}>
                                                        <HistoryIcon color='secondary' />
                                                    </IconButton>
                                                </Tooltip>
                                            </StyledTableCell>
                                            <StyledTableCell width={40} align="center">
                                                {/* <Button width="20" color='warning' variant='contained' onClick={() => handleClickOpen(shift.shiftalertid)} disabled={shift.isDeleting}>
                                            {shift.isDeleting
                                                ? <span className="spinner-border spinner-border-sm"></span>
                                                : <span>Resend</span>
                                            }
                                        </Button> */}
                                                <Tooltip title="Resend This Shift">
                                                    <IconButton aria-label="history" onClick={() => handleClickOpen(shift.shiftalertid)} disabled={shift.isDeleting}>
                                                        <MobileScreenShareIcon color='warning' />
                                                    </IconButton>
                                                </Tooltip>
                                            </StyledTableCell>
                                            <StyledTableCell width={40} align="center">
                                                {!shift.stopresponses ?
                                                    // <Button color='error' variant='contained' onClick={() => handleClickOpenStopAlert(shift.shiftalertid)} className="btn btn-sm btn-danger btn-delete-user" disabled={shift.isDeleting}>
                                                    //     <span>Stop</span>
                                                    // </Button>
                                                    <Tooltip title="Stop This Shift">
                                                        <IconButton aria-label="history" onClick={() => handleClickOpenStopAlert(shift.shiftalertid)} disabled={shift.isDeleting}>
                                                            <StopCircleIcon color='error' />
                                                        </IconButton>
                                                    </Tooltip>
                                                    :
                                                    // <Button color='error' variant='contained' onClick={() => handleClickOpenStartAlert(shift.shiftalertid)} className="btn btn-sm btn-danger btn-delete-user" disabled={shift.isDeleting}>
                                                    //     <span>Restart</span>
                                                    // </Button>
                                                    <Tooltip title="Restart This Shift">
                                                        <IconButton aria-label="history" onClick={() => handleClickOpenStartAlert(shift.shiftalertid)} disabled={shift.isDeleting}>
                                                            <PlayCircleIcon color='success' />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        {!showShiftRecommendations ?
                                            <>
                                                <StyledTableRow key={shift.shiftalertid.toString()}>
                                                    <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                                                        <Collapse in={openHistory === shift.shiftalertid} timeout="auto" unmountOnExit>
                                                            <Box sx={{ marginLeft: 4, marginTop: 1, marginBottom: 1 }}>
                                                                <Typography gutterBottom component="div" fontSize={18} color='#3A99FC' textAlign="center" sx={{ fontWeight: 'bold', m: 1 }}>
                                                                    RESPONSES
                                                                </Typography>
                                                                <Table size="small" aria-label="purchases">
                                                                    <TableHead>
                                                                        <StyledTableRow>
                                                                            <StyledTableCellAlt align="center">Username</StyledTableCellAlt>
                                                                            <StyledTableCellAlt align="center">First Name</StyledTableCellAlt>
                                                                            <StyledTableCellAlt align="center">Last Name</StyledTableCellAlt>
                                                                            <StyledTableCellAlt align="center">Credentials</StyledTableCellAlt>
                                                                            <StyledTableCellAlt align="center">Phone</StyledTableCellAlt>
                                                                            <StyledTableCellAlt align="center">Response Date</StyledTableCellAlt>
                                                                            <StyledTableCellAlt align="center">Auto Assigned?</StyledTableCellAlt>
                                                                            <StyledTableCellAlt align="center">Claimed?</StyledTableCellAlt>
                                                                            <StyledTableCellAlt align="center">Confirmed?</StyledTableCellAlt>
                                                                            <StyledTableCellAlt align="center"></StyledTableCellAlt>
                                                                        </StyledTableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {getAllShiftResponses.filter(user => user.shiftalertid === shift.shiftalertid).map(user =>
                                                                            <StyledTableRow key={user.responsedate}>
                                                                                <StyledTableCell align="center">{user.username}</StyledTableCell>
                                                                                <StyledTableCell align="center">{user.firstname}</StyledTableCell>
                                                                                <StyledTableCell align="center">{user.lastname}</StyledTableCell>
                                                                                <StyledTableCell align="center">{user.credentials}</StyledTableCell>
                                                                                <StyledTableCell align="center">{user.phone}</StyledTableCell>
                                                                                <StyledTableCell align="center">{format(new Date(user.responsedate), 'MM/dd/yyyy hh:mm a')}</StyledTableCell>
                                                                                <StyledTableCell align="center">{String(user.autoassigned)}</StyledTableCell>
                                                                                <StyledTableCell align="center">{String(user.shiftaccepted)}</StyledTableCell>
                                                                                <StyledTableCell align="center">{String(user.shiftconfirmed)}</StyledTableCell>
                                                                                <StyledTableCell align="center">
                                                                                    <Tooltip title="Remove Response">
                                                                                        <IconButton aria-label="delete" onClick={() => handleClickRemoveResponse(user)}>
                                                                                            <DeleteIcon color='error' />
                                                                                        </IconButton>
                                                                                    </Tooltip >;
                                                                                </StyledTableCell>
                                                                            </StyledTableRow >
                                                                        )}
                                                                        {// eslint-disable-next-line
                                                                            !getAllShiftResponses || getAllShiftResponses.filter(user => user.shiftalertid === shift.shiftalertid).length === 0 &&
                                                                            <StyledTableRow key={-100}>
                                                                                <StyledTableCell colSpan="7" align="center">
                                                                                    No Responses
                                                                                </StyledTableCell>
                                                                            </StyledTableRow>
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                            </Box>
                                                        </Collapse>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </>
                                            :
                                            <>
                                                <StyledTableRow key={shift.shiftalertid.toString()}>
                                                    <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                        <Collapse in={openHistory === shift.shiftalertid} timeout="auto" unmountOnExit>
                                                            <Box sx={{ marginLeft: 4, marginTop: 1 }}>
                                                                <Typography gutterBottom component="div" fontSize={18} color='#3A99FC' textAlign="center" sx={{ fontWeight: 'bold', m: 1 }}>
                                                                    RECOMMENDED USERS
                                                                </Typography>
                                                                <Table size="small" aria-label="purchases">
                                                                    <TableHead color='primary'>
                                                                        <StyledTableRow >
                                                                            <StyledTableCellAlt align="center">Username</StyledTableCellAlt>
                                                                            <StyledTableCellAlt align="center">Name</StyledTableCellAlt>
                                                                            <StyledTableCellAlt align="center">Match</StyledTableCellAlt>
                                                                            <StyledTableCellAlt align="center">Send</StyledTableCellAlt>
                                                                        </StyledTableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {getUserRecommendations.filter(user => user.shiftalertid === shift.shiftalertid).filter(excludeRecommendedUsersWhoClaimedTheShift).slice(0, 6).map(user =>
                                                                            <StyledTableRow key={user.responsedate}>
                                                                                <StyledTableCell align="center">{user.username}</StyledTableCell>
                                                                                <StyledTableCell align="center">{user.name}</StyledTableCell>
                                                                                <StyledTableCell align="center"><Tooltip title={user.usershiftscore}><CircleIcon style={{ color: getSchedulerColorByRanking(user.usershiftranking) }} /></Tooltip></StyledTableCell>
                                                                                <StyledTableCell align="center">
                                                                                    <Tooltip title="Send This Shift To User">
                                                                                        <IconButton aria-label="history" onClick={() => handleSendShiftToUser(shift, user)} disabled={shift.isDeleting}>
                                                                                            <MobileScreenShareIcon color='warning' />
                                                                                        </IconButton>
                                                                                    </Tooltip></StyledTableCell>
                                                                            </StyledTableRow >
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </Box>
                                                        </Collapse>
                                                    </StyledTableCell>
                                                </StyledTableRow></>
                                        }
                                    </React.Fragment>
                                )}
                                {!getAllShifts &&
                                    <StyledTableRow key={-2}>
                                        <StyledTableCell colSpan="4" className="text-center">
                                            <div className="spinner-border spinner-border-lg align-center"></div>
                                        </StyledTableCell>
                                    </StyledTableRow >
                                }
                                {getAllShifts && !getAllShifts.length &&
                                    <StyledTableRow key={-3}>
                                        <StyledTableCell colSpan="4" className="text-center">
                                            <div className="p-2">No Shifts To Display</div>
                                        </StyledTableCell>
                                    </StyledTableRow >
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </> :
                <>
                    <div style={{ width: '100%', margin: 5 }}>
                        <Box style={{ flexGrow: 1 }}>
                            <Scheduler
                                editable={false}
                                deletable={false}
                                selectedDate={new Date()}
                                view="month"
                                agenda={false}
                                week={{
                                    weekDays: [0, 1, 2, 3, 4, 5, 6],
                                    weekStartOn: 0,
                                    startHour: 0,
                                    endHour: 24,
                                    step: 180
                                }}
                                month={{
                                    weekDays: [0, 1, 2, 3, 4, 5, 6],
                                    weekStartOn: 0,
                                    startHour: 0,
                                    endHour: 24,
                                }}
                                day={{
                                    startHour: 0,
                                    endHour: 24,
                                    step: 120
                                }}
                                events={getAllShiftsForSchedulerView.filter(excludeStoppedFilter).filter(onlyShowInDateRange).filter(excludeRoles)}
                            />
                        </Box>
                    </div>
                </>}
            <SelectRoleDialog
                selectedValue={selectedRoleValue}
                open={selectRoleOpen}
                onClose={handleCloseSelectedRole}
            />
            <ShiftListFilterDialog
                open={showFilterDialog}
                onClose={hideFilterDialog}
                hideStoppedShifts={hideStopped}
            />

            <CreateShiftAlertDialog
                open={showCreateShiftAlertDialog}
                selecteddate={createShiftAlertSelectedDate}
                roleid={createShiftAlertSelectedRole}
                onClose={onCloseCreateShiftAlertDialog}></CreateShiftAlertDialog>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are You Sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to resend this Shift Alert?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleCloseYes} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openAreYouSureRemoveResponse}
                onClose={handleClickNoRemoveResponse}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are You Sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to remove this shift response? There is no way to undo.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickNoRemoveResponse}>No</Button>
                    <Button onClick={handleClickYesRemoveResponse} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openStopAlert}
                onClose={handleCloseStopAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Stop This Alert?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to stop allowing users from claiming this shift?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseStopAlert}>No</Button>
                    <Button onClick={handleCloseStopAlertYes} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openStartAlert}
                onClose={handleCloseStopAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Restart This Alert?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to allow users to claim this shift again?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseStartAlert}>No</Button>
                    <Button onClick={handleCloseStartAlertYes} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={openSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} onClose={handleCloseSnack}>
                <Alert onClose={handleClose} severity="success" variant="filled" sx={{ fontSize: 24 }}>
                    Successfully Sent Shift Alert!
                </Alert>
            </Snackbar>
        </div >

    );
})