import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button, Checkbox, Container, Box, Stack, Typography, Select, SelectChangeEvent, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import FormGroup from '@mui/material/FormGroup';
import { useStore } from '../../stores/store';
import { DragHandleRounded } from "@mui/icons-material";
import CompanyRoleModel from "../../models/CompanyRole";
import ShiftTemplateSetupModel from "../../models/Setups/ShiftTemplateSetup";
import * as Yup from "yup";
import { RotationSetup } from "../../models/Schedule/RotationSetup";
import { User } from "../../models/User";
import { ChangeUserInRotationDTO } from "../../models/Schedule/ChangeUserInRotationDTO";

export interface SimpleProps {
    open: boolean;
    onClose: (value: boolean,newusername: string,newname:string) => void;
    rotationid: number;
    rolename: string;
    roleid: number;
    oldusername: string;
    usersinrotation: string[];
    changeindb: boolean;
}

export default observer(function ChangeUserInRotationDialog(props: SimpleProps) {
    const { scheduleStore, userStore } = useStore();
    const { getAllUsers } = userStore;
    const { loading, putNewRotationSetup, changeUserInRotation } = scheduleStore;
    const cono = window.localStorage.getItem('cono');
    const [selectedUsername, setSelectedUsername] = React.useState('');
    const { onClose, open, rotationid, roleid, oldusername } = props;

    const formik = useFormik({
        initialValues: { cono: '', username: '' },
        validateOnBlur: true,
        onSubmit: values => {
            if (cono == null) {
                return;
            }
            let rotation: ChangeUserInRotationDTO = { cono: cono, roleid: roleid, oldusername: oldusername, newusername: selectedUsername, rotationid: rotationid };

            if (props.changeindb) {
                changeUserInRotation(rotation).then(() => {
                    onClose(true,'','');
                    formik.resetForm();
                });
            }
            else{
                //do whatever to change in memory, ApplyRotation will do it
                let name = '';
                getAllUsers.forEach((s) => {
                    if (s.username == selectedUsername) {
                        name = s.firstname + ' ' + s.lastname + ' ';
                    }
                })
                onClose(true,selectedUsername,name);
                formik.resetForm();
            }
        }
    });

    function excludeRoles(user: User) {
        let result: boolean;
        result = false;
        const values = user.Roles.split(',');
        values.forEach((v: string) => {
            if (v.trim() === props.rolename.trim()) {
                result = true;
            }
        })
        if (user.username.toUpperCase().trim() === props.oldusername.toUpperCase().trim()) {
            result = false;
        }
        props.usersinrotation.forEach((u: string) => {
            if (user.username.toUpperCase().trim() === u.toUpperCase().trim()) {
                result = false;
            }
        })

        return result;
    }

    const handleReset = () => {
        console.log('try to reset');
        formik.resetForm({
            values: { cono: '', username: '' },
        });
    };

    const handleChange = () => {

    };
    const handleSelectUsername = (e: SelectChangeEvent<string>) => {
        console.log('change user' + e.target.value.toString());
        setSelectedUsername(e.target.value.toString());
        formik.handleChange(e);
    }

    const handleClose = () => {
        setSelectedUsername('');
        formik.resetForm();
        onClose(false,'','');
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Change User</DialogTitle>
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <DialogContent dividers={true}>
                    <Typography>Previous User:</Typography>
                    <Typography>{props.oldusername} - {props.rolename}</Typography>
                    <FormGroup>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>New User</InputLabel>
                        <Select
                            name="selectedUsername"
                            value={selectedUsername}
                            onChange={handleSelectUsername}
                            sx={{ width: 360, marginBottom: 2 }}>
                            {getAllUsers.filter(excludeRoles).map((role) => (
                                <MenuItem key={role.username} value={role.username}>{role.lastname}, {role.firstname} ({role.username})</MenuItem>
                            ))}
                        </Select>
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClose}>Cancel</Button>
                    <LoadingButton variant='contained' loading={loading} type="submit">Save</LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
    )
})